import React, { useContext, useEffect, useState, useRef } from 'react'

import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Modal, Grid, Chip, Fab, Divider, Snackbar, CardActions, CardContent, Icon, CardActionArea, useMediaQuery,
  
 } from '@mui/material';
import { UserContext } from '../context/usercontext';
import Progress from '../components/mods/progress'
import { useItem } from '../components/finance/mangeshop/useitems'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ArrowBack } from '@mui/icons-material'

import  useFileUpload  from '../components/profile/cloud'
import { useParams, Link } from 'react-router-dom'
import coffeebeans from '../images/coffeebeans.png'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';

//import RedeemCoffee from '../components/finance/redeemcoffee'
import { makeStyles  } from '@mui/styles'
import { useSpring , animated} from 'react-spring'
import BeanCard from '../components/mods/beancard'
import AiGen2 from '../components/finance/adds/aifeed2'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';
import AddIcon from '@mui/icons-material/Add';
import MultiFileUpload from '../components/profile/productui'
import BeanReport from '../components/finance/prods/beanreport'


const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: 20,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    minWidth: 400,
    boxShadow: 'none',
  },
}));


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



const KUPSTER_BY_USERID = gql`
  query KupsterByUserID($userID: ObjectId!) {
    kupster(query: { userID: $userID }) {
      Title
      _id
      favorite_koffie
      userID
      
    }
  }
`;
const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const MY_ADDS = gql`
  query MyAdds ($userID: String!) {
    aiadds (query: {userID: $userID}) {
    Title
		When
		_id
		description
		price
		primaryImage
		productID
		userID
    beanamount
    sales_pitch
  }
}
  
`; 

const UPDATE_ISADD = gql`
  mutation UpdateOneAiadd($query: AiaddQueryInput!, $set: AiaddUpdateInput!) {
    updateOneAiadd(query: $query, set: $set) {
      _id
      isAdd
      # Include other fields you might want to return
    }
  }
`;

const DELETE_ONE_AIADD = gql`
  mutation DeleteOneAiadd($query: AiaddQueryInput!) {
    deleteOneAiadd(query: $query) {
      _id
      
    }
  }
`;


const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

function KupsterDash() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const { __id } = useParams();
  const [expanded, setExpanded] = React.useState(false);
 const { Nickname } = user.customData;
 const [expandedIndex, setExpandedIndex] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);
  

  //Multiple Image Upload
  const multiImageUploadRef = useRef();

  // Separate function for handling uploaded images
  const handleImagesUploaded = async (userId) => {
    try {
      const uploadedImages = await multiImageUploadRef.current.handleSubmit(userId);
      
      // Handle the uploaded images
      const productData = {
        imageurl: uploadedImages.find(img => img.field === 'imageurl')?.url,
        imageurl1: uploadedImages.find(img => img.field === 'imageurl1')?.url,
        imageurl2: uploadedImages.find(img => img.field === 'imageurl2')?.url,
        imageurl3: uploadedImages.find(img => img.field === 'imageurl3')?.url,
        imageurl4: uploadedImages.find(img => img.field === 'imageurl4')?.url,
      };

      // Update your product with the new image URLs
      // updateProduct(productData);
    } catch (error) {
      console.error('Error handling uploaded images:', error);
    }
  };
  //open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  //close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //handle isdraweropen1
  const handleDrawerOpen2 = () => {
    setIsDrawerOpen1(true);
  };

  const handleDrawerClose2 = () => {
    setIsDrawerOpen1(false);
  };

  
  const classes = useStyles();
 
  const { loading, error, data, refetch } = useQuery(KUPSTER_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });
   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });
 // likes count
  const likesCount = likesData?.likes.length || 0;

  // comments count
  const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });

  if (errorComments) {
    console.error("Error fetching comments:", errorComments.message);
  }
  const commentsCount = commentsData?.comments.length || 0;

  const { file, previewURL, handleFileChange, uploadFile, handleRemoveFile } = useFileUpload(); // Use your custom hook
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [postSubmitted, setPostSubmitted] = useState(false); // Added state for postSubmitted
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = React.createRef();
    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: user.id },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);
    const { data: aiAdds, loading: aiLoading, error: aiError } = useQuery(MY_ADDS,
      {variables: { userID: user ? user.id : null } });
      const [updateIsAdd] = useMutation(UPDATE_ISADD);
      const [message, setMessage] = useState('');
  
      //Delete one Add
  const [deleteOneAiadd] = useMutation(DELETE_ONE_AIADD,
    { refetchQueries: [{ query: MY_ADDS, variables: { userID: user ? user.id : null } }] });
    
    const handlePhotoClick = () => {
      fileInputRef.current.click();
    };
 
    const handleDeleteAdd = async (item) => {
      try {
        const { data } = await deleteOneAiadd({
          variables: {
            query: { _id: item._id },
          },
        });
  
        if (data.deleteOneAiadd) {
          setMessage(`Deleted: ${data.deleteOneAiadd.name}`);
          // Optionally, update local state or refetch queries here
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    };
  



  // Set the Add to ture
  const handleUpdateIsAdd = (_id) => {
    updateIsAdd({
      variables: {
        query: { _id: _id }, // Specify which document to update
        set: { isAdd: true } // Set isAdd to true
      }
    })
    if (beanCount < 200) {
      setShowDialog(true); // This will automatically show the dialog
      return;
    }
    decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -200 }
    })

    .then(response => {
      console.log('Update successful', response);
      handleOpenModal();
      // Perform any actions after successful update, e.g., refresh data or UI
    })
    .catch(error => {
      console.error('Error updating isAdd', error);
    });
  };
    

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

    const handleExpandClick = (index) => {
      setExpandedIndex(expandedIndex === index ? null : index);
    };
    

    const [bounce, setBounce] = useState(false);
    const [bounceCount, setBounceCount] = useState(0);
    const bounceAnimation = useSpring({
      transform: bounce
        ? 'translateY(-10px) scale(1.1)'
        : 'translateY(0px) scale(1)',
      config: { duration: 2000 },
    });
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (bounceCount < 50) {
          setBounce((prevState) => !prevState);
          setBounceCount((prevState) => prevState + 1);
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setBounceCount(0);
            interval && setInterval(() => {
              setBounce((prevState) => !prevState);
              setBounceCount((prevState) => prevState + 5);
            }, 2500);
          }, 1000);
        }
      }, 2500);
  
      return () => {
        clearInterval(interval);
      };
    }, [bounceCount]);
  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [createKupster] = useMutation(CREATE_KUPSTER,
    {
      refetchQueries: [{ query: KUPSTER_BY_USERID, variables: { userID: user ? user.id : null } }],
      variables: { _id: user.id },
    });

  const handleDrawerOpen1 = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose1 = () => {
    setIsDrawerOpen(false);
  };
  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    // Prepare the input data
    const input = {
      flavor_profile: formData.get('Title'),
      favorite_koffie: formData.get('favorite_koffie')
     
    };
  
    // Check if the data exists (you need a way to determine this)
    if (dataExists) { // Implement your logic to determine if data exists
      // Update the profile
      updateKupster({ variables: { data } })
        .then(response => {
          console.log('Profile Updated:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not update profile:', error);
        });
    } else {
      createKupster({ variables: { data } })
        .then(response => {
          console.log('New Profile Created:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not create profile:', error);
        });
    }
  };
  
  // Drawer with Form
  const renderDrawer = () => (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose1}>
      <Box
        sx={{ width: '100%', padding: 3, backgroundColor: '#fff'}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton onClick={handleDrawerClose1}>
          <ArrowBack />
        </IconButton>
        <Card sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h4" component="h2"fontWeight={500} color='#e39024'>
          Coming Soon!
          </Typography>
          </Card>
        <TextField label="Title" name="Title" fullWidth />
        <TextField label="Favorite Koffie" name="favorite_koffie" fullWidth />

        <Button type="submit">Save Settings</Button>
      </Box>
    </Drawer>
  );

  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};



 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    beanamount: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
   

    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
     
      return;
    }

    setIsUploading(false); // End the upload process
    await decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -50 }
    });
    const uploadedImageURL = await uploadFile(file, user.id);
   
    // Check if the upload was successful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      handleCloseDialog();
      return;
    }
    
   
  
    // Decrement beanCount on the client side for immediate feedback
    setBeanCount(beanCount - 50);
    
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
      imageurl: uploadedImageURL, // Use the uploaded image URL here
      imageurl1: uploadedImageURL,
      imageurl2: uploadedImageURL,
      imageurl3: uploadedImageURL,
      imageurl4: uploadedImageURL,
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
    
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  
  const handleFabClick = () => {
    fileInputRef.current.click();
};




  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
     <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
      
    
      
      
     <Grid container spacing={2} justifyContent="center" alignItems="stretch">
  <Grid item xs={12} md={12}>
    
     <BeanCard />
    
  </Grid>
</Grid>

     
    </Box>
     
      {data && data.kupster ? (
        Array.isArray(data.kupster) ? (
          data.kupster.map((item, index) => (
            <div key={index}>
              {item.favorite_koffie ? item.favorite_koffie : "No Favorite"}
              {item.Title ? item.Title : "No Title"}
            </div>
          ))
        ) : (
          <Card raised style={{ textAlign: 'center', borderRadius: 5 }}>
            {data.kupster.favorite_koffie ? data.kupster.favorite_koffie : "No Favorite"}
            {data.kupster.Title ? data.kupster.Title : "No Title"}
          </Card>
        )
      ) : (
        <Card  raised  sx={{ textAlign: 'center', borderRadius: 5, backgroundColor: '#009dd1' }}>
          <CardContent ax={{backgroundColor: '#009dd1'}}>
          <Typography variant="body2" gutterBottom>
            Options
          </Typography>
          <Typography variant="subtitle1">
            Set up your custom settings for a better experience
          </Typography>
          <Chip 
  label="Customize Settings"  
  style={{ margin: '10px 0' }} 
  onClick={handleDrawerOpen1} 
/>
          </CardContent>
        </Card>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
      {!isMobile && (
     <Drawer anchor='right' open={drawerOpen} onClose={handleDrawerClose1}sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
      <Box sx={{ maxWidth: 400, padding: 3,  border: 'solid 1px #000', paddingBottom: 2}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
         <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex', padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={100} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
       
            <Chip label="Update payment method" onClick={handleDrawerOpen2} /> 
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 0px #d1d1d1', marginTop: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. 
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt:2 }}> 
            <Fab size='medium'  onClick={handleOpenDialog} aria-label="add" sx={{  }} >
            <AddIcon />
            </Fab>  
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary', mt: 1 }}>
              Add Product
            </Typography>   
            
            </Box> 
      </CardContent>
      
    </Card>

<Drawer anchor="right" open={isDrawerOpen1} onClose={handleDrawerClose2}>
<Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    padding: 3, 
    alignItems: 'center',
    justifyContent: 'space-between' 
  }}
>
  <IconButton onClick={handleDrawerClose2}>
    <ArrowBack />
  </IconButton>
  <Typography variant="h6" sx={{ fontWeight: 600, ml: 'auto' }}>
    Payment Method
  </Typography>
</Box>

      <Box sx={{ width: '100%', padding: 3 }}>
        
        <BeanReport />
      </Box>
    </Drawer>

      
          <Dialog  open={openDialog} onClose={handleCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>
<MultiFileUpload />
        
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
        
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 2 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>
        </div>
        </Box>
      </Drawer>

    )}
    </div>
    
   <div>
   {isMobile && (
          <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "100%" }}
      >
        <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex',  padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={100} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
       
            <Chip label="Update payment method" onClick={handleDrawerOpen2} /> 
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 0px #d1d1d1', marginTop: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. 
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt:2 }}> 
            <Fab size='medium'  onClick={handleOpenDialog} aria-label="add" sx={{  }} >
            <AddIcon />
            </Fab>  
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary', mt: 1 }}>
              Add Product
            </Typography>   
            
            </Box> 
      </CardContent>
      
    </Card>
    <Drawer anchor="bottom" open={isDrawerOpen1} onClose={handleDrawerClose2}>
    <Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    padding: 3, 
    alignItems: 'center',
    justifyContent: 'space-between' 
  }}
>
  <IconButton onClick={handleDrawerClose2}>
    <ArrowBack />
  </IconButton>
  <Typography variant="h6" sx={{ fontWeight: 600, ml: 'auto' }}>
    Payment Method
  </Typography>
</Box>

      <Box sx={{ width: '100%', padding: 0, }}>
       
        <BeanReport />
      </Box>
    </Drawer>
          <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} height= '100vh'>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>

         <MultiFileUpload />
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent sx={{ padding: 2 ,}}>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
         
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List sx={{ marginBottom: 6 }}>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 0 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>

      
    </div>
      </Drawer>
)}
</div>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 1}}>
   
    {products.length > 0 ? (
      <>
       <Card sx={{ 
   
   width: '100%',
   display: 'flex', 
   alignItems: 'left', 
   justifyContent: 'space-between', 
   padding: '0 10px',
   boxShadow: 0,
   mt: 2,
    mb: 2,
    
   
 }}>
   <Typography variant="h6" sx={{ fontWeight: 700,  }}>
     Manage Gallery 
   </Typography>
   
   <Chip
     label="Manage Shop"
     onClick={handleDrawerOpen}
     
     style={{ cursor: 'pointer', backgroundColor: '#2fa849', color: '#fff' }}
   />
 </Card>

 <Box sx={{ display: 'flex',  flexDirection: 'column', padding: 0 }}>
    <Typography variant="h6" sx={{ fontWeight: 600,  margin: 1 }}>
      Your Products
    </Typography>
    <Divider variant='middle'  />
  </Box>
 <Grid container spacing={3} sx={{ padding: 2 }}>
 
 {products.map((product) => (
   <Grid item key={product._id} xs={6} sm={6} md={3}>
     <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
       <Card sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 3 }}>
         <CardMedia
           component="img"
           height="200"
           image={product.imageurl}
           alt={product.name}
           sx={{ objectFit: 'cover' }}
         />
         <CardContent sx={{ padding: 2 }}>
           <Typography variant="body1" sx={{ fontWeight: 600,  }}>
             {product.name}
           </Typography>
           <Typography variant="body1" color="text.secondary">
             ${ (parseFloat(product.price) / 100).toFixed(2) }
           </Typography>
         </CardContent>
       </Card>
     </Link>
   </Grid>
 ))}
</Grid>
    </>
    ) : (
      // Display a Card instead of a div for the message
      <Box sx={{ mt: 0, mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 4, bgcolor: '#00cc99', borderRadius: 5 }}>
          <Typography variant="h6" color="white"fontWeight={300}>
            Stimulate the economy with your artisanal goods and services
          </Typography>
          <Typography variant="body1">
            Let the AI smart cart sell for you.
          </Typography>
          
            <button
             onClick={handleDrawerOpen}
              className={classes.buttonContainer}
            >
              Start Selling
            </button>
            
          
        </Box>
    )}
    </div>
    
    
   
      <Card sx={{
        height: 'auto', // Changed from '10vh' to 'auto' to accommodate the list
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Changed to 'column' to stack items vertically
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
       
        
      }}>
         <CardMedia sx={{ textAlign: 'center', width: '100%', padding: 0, mt: 2, mb: 2, bgcolor: 'transparent',  }}>
            <Typography variant="h6" gutterBottom sx={{fontWeight:700, textAlign: 'left', }}>
            Add generated add to live feed/market
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', }}>
            The AI smart cart has generated some smart sales for you. Add them to the live feed to generate user interest.
            </Typography>
          </CardMedia>
          {aiAdds && aiAdds.aiadds.length > 0 ? (
  Array.isArray(aiAdds.aiadds) ? (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 , padding: 1}}>
      {aiAdds.aiadds.map((item, index) => (
        <Card key={index} sx={{ width: '100%', flexDirection: 'column', boxShadow: 5, borderRadius: 2,  padding: 1, border: 'solid 1px'}}>
          <CardMedia
            component="img"
            height="150"
            image={item.primaryImage}
            alt={item.Title ? item.Title : "No Title"}
            style={{ objectFit: 'cover', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
          />
          <CardContent >
            <Typography variant="h6" gutterBottom>
              {item.Title ? item.Title : "No Title"}
            </Typography>
            <Typography variant="body1" color='textSecondary'
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2, /* number of lines to show */
              WebkitBoxOrient: 'vertical'
            }}
            >
              {item.sales_pitch ? item.sales_pitch : "No Description"}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
  <Typography 
    variant="body2" 
    
    onClick={() => handleExpandClick(index)}
    aria-expanded={expandedIndex === index}
    sx={{ cursor: 'pointer', textDecoration: 'none', color: 'green' }}
  >
    Read more...
  </Typography>
</Box>

          </CardContent>
          <CardActions>
            <Chip label="Add to Market" onClick={() => handleUpdateIsAdd(item._id)} />
            <Chip label="Remove" onClick={() => handleDeleteAdd(item)} />
            <Box sx={{ flexGrow: 1 }} />
            <Chip
              label="200"
              avatar={<Avatar src={coffeebeans} alt="Bean" />}
              variant="outlined"
            />

           
          </CardActions>
          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                {item.sales_pitch ? item.sales_pitch : "No Description"}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography variant="body1">
            {aiAdds.aiadds.sales_pitch ? aiAdds.aiadds.sales_pitch : "No Description"}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body1">
            {aiAdds.aiadds.price ? aiAdds.aiadds.price: "No Price"}
          </Typography>
        </CardActions>
      </Card>
    </div>
  )
) : (
  <Card sx={{ textAlign: 'center', width: '100%', padding: '20px', marginTop: '10px' }}>
    <Typography variant="h6" gutterBottom>
      No Smart Sales Generated Yet
    </Typography>
    <Typography variant="body2" color="green">
      <Link to="/post" style={{ textDecoration: 'none', color: 'inherit' }}>
        Post and share to generate user interest
      </Link>
    </Typography>
  </Card>
)}


      </Card>
<Modal open={OpenModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
   
 }}>
  <Box sx={{ width: 400, padding: 3,  borderRadius: 5, backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff', border: 'solid 1px #d1d1d1' }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
  <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'left' }}>
    Market Place
  </Typography>
  <IconButton onClick={handleCloseModal}>
    <CloseIcon />
  </IconButton>
</Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
      <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='newton' width={80} />
    
    </Box>
    <Typography variant="h6" sx={{ textAlign: 'center', margin: 2 }}>
      300 beans deducted
    </Typography>
    <Typography variant="body1" sx={{ textAlign: 'center', margin: 2,  }}>
      {beanCount} beans remaining
    </Typography>
    <Typography variant="body1" sx={{ textAlign: 'center', margin: 2,  }}>
      Your Item has been added to the market. Users can now view and purchase your product from the live feed.
    </Typography>
   
  </Box>
</Modal>   

  </div>
  )
}

export default KupsterDash