import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  CardMedia,
  Chip,
  Tooltip,
  CardActionArea,
  Drawer
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { gql, useQuery } from '@apollo/client';
import Progress from '../progress';
import { motion, AnimatePresence } from 'framer-motion';

const GET_STORIES = gql`
  query {
    darkroads(limit: 100) {
      ImageUrl
      When
      How
      What
      Title
      _id
    }
  }
`;

const KoffieHistory = () => {
  const [fact, setFact] = useState(null);
  const [selectedFact, setSelectedFact] = useState(null); // Holds only selected fact for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { loading, error, data } = useQuery(GET_STORIES);

  useEffect(() => {
    if (data && data.darkroads.length > 0) {
      generateFact();
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 3000);

    return () => clearInterval(interval);
  }, [data]);

  const generateFact = () => {
    if (data && data.darkroads.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.darkroads.length);
      setFact(data.darkroads[randomIndex]);
    }
  };

  const handleOpenDrawer = (fact) => {
    setSelectedFact(fact); // Save only the selected fact
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  if (loading) return <Typography><Progress /></Typography>;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
      {fact && (
        <Card
          sx={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            borderRadius: 5,
            backgroundColor: 'transparent',
            overflow: 'hidden',
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 130, objectFit: 'cover', borderRadius: 0 }}
            image={fact.ImageUrl}
            alt="Fact Image"
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ padding: 0.5 }}>
              <AnimatePresence mode="wait">
                {fact && (
                  <motion.div
                    key={fact._id}
                    initial={{ opacity: 0, y: 20, scale: 0.9 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: -20, scale: 0.95 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {fact.Title}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 900 }}>
                        {fact.When}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 400 }}>
                      {fact.What}
                    </Typography>
                  </motion.div>
                )}
              </AnimatePresence>
            </CardContent>

            <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
              <Tooltip title="Learn More" arrow>
                <Chip
                  onClick={() => handleOpenDrawer(fact)}
                  label="Learn more"
                  variant="outlined"
                  sx={{ color: '#077336', padding: 1.5, cursor: 'pointer' }}
                />
              </Tooltip>
            </CardActionArea>
          </Box>
        </Card>
      )}

      {/* DRAWER */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        sx={{ zIndex: 1301 }} // Ensures drawer is on top
      >
        {selectedFact && ( // Ensure drawer only displays the selected fact
          <Box sx={{ width: 350, padding: 2 }}>
            {/* Close Button */}
            <IconButton
              onClick={handleCloseDrawer}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>

            {/* Image */}
            <CardMedia
              component="img"
              sx={{ width: '100%', height: 200, objectFit: 'cover', borderRadius: 2 }}
              image={selectedFact.ImageUrl}
              alt={selectedFact.Title}
            />

            {/* Content */}
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {selectedFact.Title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'gray' }}>
                {selectedFact.When}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                {selectedFact.What}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                {selectedFact.How}
              </Typography>
            </CardContent>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default KoffieHistory;
