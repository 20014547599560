import React, { useContext, useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { Button, Box, TextField } from "@mui/material";
import { UserContext } from "../../context/usercontext";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import useFileUpload from "../profile/cloud"; // Import file upload hook
import ApiKeyGenerator from "../../constants/api/keygenui";

const UPDATE_KOFFIESHOP = gql`
  mutation UpdateKoffieshop($set: KoffieshopUpdateInput!, $query: KoffieshopQueryInput) {
    updateOneKoffieshop(set: $set, query: $query) {
      _id
      qrcode
      userID
      qrgenAt
      dailyPIN
      pinUpdatedAt
    }
  }
`;

const GET_KOFFIESHOP = gql`
  query GetKoffieshop($query: KoffieshopQueryInput) {
    koffieshop(query: $query) {
      _id
      qrcode
      userID
    }
  }
`;

const QRCodeGenerator = ({ onClose }) => {
  const [pin, setPin] = useState('');
  const { user } = useContext(UserContext); // Access user context
  const [qrCodeVisible, setQrCodeVisible] = useState(false); // Controls QR code visibility
  const [qrCodeDataURL, setQrCodeDataURL] = useState(""); // Stores QR code image as a data URL
  const { uploadFile } = useFileUpload(); // File upload hook
  const [updateKoffieshop] = useMutation(UPDATE_KOFFIESHOP); // Mutation hook
  const [getKoffieshop] = useLazyQuery(GET_KOFFIESHOP, {
    variables: { query: { userID: user.id } },
    fetchPolicy: 'network-only', // Ensure fresh data is fetched
    onCompleted: (data) => {
      if (data.koffieshop) {
        setQrCodeDataURL(data.koffieshop.qrcode); // Update QR code data
      }
    },
  });

  useEffect(() => {
    if (qrCodeVisible) {
      const svg = document.getElementById("qrCodeSVG");
      if (!svg) {
        console.error("QR Code SVG element not found!");
        return;
      }

      try {
        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svg);

        const canvas = document.createElement("canvas");
        canvas.width = 256;
        canvas.height = 256;

        const ctx = canvas.getContext("2d");
        const img = new Image();
        const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(url);

          const dataURL = canvas.toDataURL("image/png");
          setQrCodeDataURL(dataURL);
          console.log("QR Code generated successfully!");
        };

        img.onerror = (err) => {
          console.error("Error loading SVG as an image:", err);
          alert("Failed to generate QR Code. Please try again.");
        };

        img.src = url;
      } catch (error) {
        console.error("Error serializing SVG element:", error);
        alert("Failed to generate QR Code. Please try again.");
      }
    }
  }, [qrCodeVisible]);

  const saveQRCodeToBackend = async () => {
    if (!qrCodeDataURL) {
      alert("Generate the QR code first!");
      return;
    }

    try {
      // Convert data URL to Blob
      const blob = await fetch(qrCodeDataURL).then((res) => res.blob());
      const file = new File([blob], `QRCode_${user.id}.png`, { type: "image/png" });

      // Upload file using the `useFileUpload` hook
      const uploadedUrl = await uploadFile(file, user.id);

      // Save the uploaded URL to the backend via mutation
      await updateKoffieshop({
        variables: {
          set: { qrcode: uploadedUrl },
          query: { userID: user.id },
        },
      });

      // Refetch Koffieshop data
      getKoffieshop();

      // Close the drawer
      if (onClose) onClose();

      alert("QR Code saved successfully!");
    } catch (error) {
      console.error("Error saving QR Code:", error);
      alert("Error saving QR Code URL.");
    }
  };

  return (
    <Box >
      <Box sx={{ textAlign: "center" }}>
  {/* Generate Button */}
  <Button
  variant="contained"
  onClick={() => setQrCodeVisible(true)}
  sx={{
    mb: 2,
    mt: 2,
    bgcolor: "#000",
    color: "white",
    "&:hover": { bgcolor: "#055826" }, // Slightly darker green
    "&:active": { bgcolor: "#044e24" }, // Even darker green when clicked
  }}
>
  Generate your QR Code
</Button>

</Box>


      {/* Display QR Code */}
      {qrCodeVisible && (
        <Box sx={{ textAlign: "center", mt: 2 , backgroundColor: "#f0f0f0", padding: 2, borderRadius: 2 }}>
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              
              width: "100%",
            }}
          >
            <QRCode
              id="qrCodeSVG"
              size={256}
              style={{
                display: "block",
                height: "auto",
                maxWidth: "100%",
                width: "100%",
              }}
              value={`https://koffieland.io/claimbeans/${user.id}`}
              viewBox={`0 0 256 256`}
            />
          </div>

          {/* Save to Backend Button */}
          <Button
            variant="contained"
            color="success"
            onClick={saveQRCodeToBackend}
            sx={{ mt: 2 }}
          >
            Activate Digital Beans
          </Button>
        </Box>
      )}
      <Box sx={{ mt: 3, mb: 2 }}>
  <TextField
    label="Shop PIN"
    variant="outlined"
    value={pin}
    onChange={(e) => setPin(e.target.value)}
    fullWidth
    inputProps={{ style: { textAlign: 'center', fontWeight: 'bold' } }}
  />
</Box>
<Button sx={{ mt: 2, mb: 2 }} variant="contained" color="primary" onClick={() => {
  if (pin === '') {
    alert('Please enter a PIN');
  }
  else {
    updateKoffieshop({
      variables: {
        set: { dailyPIN: pin },
        query: { userID: user.id },
      },
    });
    alert('PIN updated successfully');
    setPin('');
  }
}
}>Set Pin</Button>
<ApiKeyGenerator />
    </Box>
  );
};

export default QRCodeGenerator;
