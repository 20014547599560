import React, { useState, useContext } from 'react';
import { Card, Grid, Paper, Typography, Box, Avatar, CardMedia, Chip, CardContent, CardActions, Modal,
Button, CardActionArea, TextField, IconButton, useMediaQuery, 
Drawer} from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import congo from '../../images/congobag.png';
import Progress from '../mods/progress';
import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddShoppingCart, Close } from '@mui/icons-material';
import StackableFoot from './displays/stackfoot';
import { BagContext,useBag } from '../../context/BagContext';
import { UserContext } from '../../context/usercontext';
import IvyAdd from '../finance/adds/ivyadd';
import KoffieCalc from '../finance/koffiecalc';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSpring, animated, config } from 'react-spring';
import { useTheme } from '@mui/material/styles';
import StackableRoad from './displays/stackroad';
import StoreEquip from '../finance/adds/k-equip';
import { motion } from 'framer-motion';




const useStyles = makeStyles((theme) => ({
    transparentChip: {
        backgroundColor: 'transparent',
        border: 1,
        borderColor: '#32a852',
        color: '#32a852',
    },
   
}));

const GET_PRODUCTS = gql`
  query Products($isavail: Boolean!) {
    products(query: { isavail: $isavail }) {
      Title
      _id
      description1
      description2
      description4
      imageurl
      countryName
      prodowner
      name
      price
      farmer
      isavail
      category
      ownername
      ownerimage
    }
  }
`;

export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!) {
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
    }
  }
`;

// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    category
    beanies
    name
    prodowner
    quantity
    options {
      size
      color
    }
  }
}
`;

const Homes = () => {
    const { user } = useContext(UserContext);
    const { itemsInBag , setItemsInBag} = useBag();
    const classes = useStyles();
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isClicked, setIsClicked] = useState(false);  
    const { loading, error, data } = useQuery(GET_PRODUCTS, {
        variables: { isavail: true },
      });
    const navigate = useNavigate();
   
    const [searchTerm, setSearchTerm] = useState('');

    const [insertCart, ] = useMutation(INSERT_CART_MUTATION);

    const [open, setOpen] = useState(false);
    const [OpenModal, setOpenModal] = useState(false);

    // Handle Open Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //Navigate to Koffie Calculator
    const goToCalc = () => {
        navigate('/calculator');
    };

    // uspring animation
    const animationStyles = useSpring({
        from: { transform: 'translateY(-100%)', opacity: 0 },
        to: async (next) => {
          // Falls and bounces into place more slowly
          await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
          // Zooms out slowly
          await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
          // Zooms back in to its original state more slowly
          await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
          // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
          await next({ opacity: 1, config: { duration: 500 } });
        },
        delay: 600,
      });

    
    if (loading) return <div><Progress /></div>;
    if (error) return <div>{null}</div>;

    // Group products by category
    const productsByCategory = data.products.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    if (loading) return <Progress />;
    if (error) return <Typography>Error fetching data</Typography>;

    const handleClick = () => {
        setIsClicked(true);
        // Reset the animation state after a short delay
        setTimeout(() => setIsClicked(false), 300);
      };

    const handleAddToCart3 = async (product) => { 
        handleClick();
      const cartData = {
        data: {
          userID: user.id,
          productId: product._id,
          price: product.price,
          
          isPaid: false,
          name: product.name,
          imageurl: product.imageurl,
          category: product.category,
          prodowner: product.prodowner,
          quantity: 1,
          options: {
            size: "M",
            color: "Red"
          }
        }
      };
    
      try {
        await insertCart({
          variables: cartData
        });
        setItemsInBag((prevItems) => [...prevItems, product]);  // UI feedback action
      } catch (error) {
        console.error("Error inserting cart:", error);
      }
    };

    const desiredCategories = ['Home']; // Specify desired categories

     // Filter products based on search term
     const filteredProductsByCategory = Object.keys(productsByCategory).reduce((acc, category) => {
      acc[category] = productsByCategory[category].filter(product =>
          product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
  }, {});

    return (

        
      
        
        <Box sx={{ flexGrow: 1, height: '100hv' }}>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
              alignItems: 'center',
               gap: 1,
                boxShadow: 1,
                 borderRadius: 2,
                  padding: 2,
                   m: 1,
                   backgroundColor: '#ba55d3',
             }}>
              <Box
      component={motion.div}
      initial={{ opacity: 0, x: -50 }} // Start state (invisible and below)
      animate={{ opacity: 1, x: 0 }} // End state (fully visible and in place)
      transition={{ duration: 0.8, ease: 'easeOut' }} // Animation timing
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Vertically align the image and text
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 300, mb: 1 , fontStyle: 'italic',  color: 'white' }}>
        Build the Bean economy when you purchase from Koffielandiers
      </Typography>
      <img
        src="https://storage.googleapis.com/app_darkendimg/assets/jetset.png"
        alt="Beancoin"
        style={{ width: 80, height: 80, borderRadius: '50%' }}
      />
    </Box>
    </Box>

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, padding: 1 }}>
<TextField
      label="Search by Name"
      variant="outlined"
      size="small"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      sx={{ flexGrow: 1, marginRight: 2 , borderRadius: 8}}
    />
    <Link to="/bag" style={{ textDecoration: 'none' }}>
      <Chip
        label={`Bag (${itemsInBag.length})`}
        sx={{  fontWeight: 600,  borderRadius: '50px' }}
        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/royal.png' alt="Congo" />}
      />
    </Link>
  </Box>        
  {Object.keys(filteredProductsByCategory).map((category) => (
  desiredCategories.includes(category) &&
  filteredProductsByCategory[category].length > 0 && (
    <div key={category}>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 2, mb: 8 }}>
        {filteredProductsByCategory[category].map((product) => (
          <Grid item xs={6} sm={4} md={6} key={product._id}>
            <Card
              sx={{
                borderRadius: 3,
                boxShadow: 6,
                transition: "transform 0.3s ease",
                "&:hover": { transform: "scale(1.05)" },
                position: "relative", // Required for overlay text
              }}
            >
              <CardActionArea onClick={() => navigate(`/product/${product._id}`)}>
                {/* Product Image */}
                <CardMedia
                  component="img"
                  height="200"
                  image={product.imageurl}
                  alt={product.name}
                  sx={{ objectFit: "cover", borderRadius: "12px 12px 0 0" }}
                />

                {/* Overlay Text */}
                <Typography
                  variant="body2"
                  sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff",
                    fontWeight: 600,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {product.countryName}
                </Typography>

                {/* Product Content */}
                <CardContent>
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {product.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limit to 2 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product.description1}
                  </Typography>
                  <Typography variant="h6" color="text.secondary" fontWeight="600">
  ${(product.price / 100).toFixed(2)}
</Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{ justifyContent: "space-between", padding: 1, }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar src={product.ownerimage} alt={product.ownername} sx={{ width: 24, height: 24 }} />
                  <Typography variant="caption" color="text.secondary" sx={{  }}>
                    {product.ownername}
                  </Typography>
                  </Box>
                 
                 <Chip label="Add to Bag" onClick={() => handleAddToCart3(product)} sx={{backgroundColor: '#077336', color: "#fff"}} />
                </CardActions>

            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
))}

        </Box>
        
        
    );
};

// Component to fetch and display image
function UserImage({ userID }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChipClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { loading, error, data } = useQuery(GET_ROASTERIES, {
        variables: { _id: userID },
    });

    if (loading) return <img src="https://via.placeholder.com/30" alt="user" style={{ width: 30, height: 30, borderRadius: 50 }} />;
    if (error) return <Chip
        label="Coming Soon"
        avatar={<Avatar src="https://storage.googleapis.com/app_darkendimg/assets/farmericon.jpg" alt="Bean" />}
        variant="outlined"
        style={{ marginTop: 8, }}
    />;

    const userimage = data.userprofs[0]?.imageurl || 'Unknown';
    const username = data.userprofs[0]?.Nickname || 'Unknown';
    const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';

    return (
        <>
            <Chip
                label={username}
                onClick={handleChipClick}
                avatar={<Avatar src={userimage} alt={username} />}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 16 }}>
                    <Typography variant="body1">{userstory}</Typography>
                </div>
            </Popover>
        </>
    );
}

export default Homes;
