import React, { useState, useContext, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import Plot from 'react-plotly.js';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import {useMediaQuery } from '@mui/material';
import { Box, Card, CardContent, Chip, Typography, List, Button, Dialog, Grid, CardMedia,
    DialogContent,  CardActions, CardActionArea, Snackbar, Avatar, Alert, Slide
    
 } from '@mui/material';
import { ArrowBackIos, } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext, useUserContext } from '../context/usercontext';
import { useLazyQuery, useMutation } from '@apollo/client';
import Progress from '../components/mods/progress';
import { useBag } from '../context/BagContext';
import { BagContext } from '../context/BagContext';
import { AddShoppingCart } from '@mui/icons-material';
import { Clear} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { set } from 'lodash';
import FactGen from '../components/mods/factgen';



const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide 
      direction="up" 
      ref={ref} 
      {...props} 
      timeout={{ enter: 500, exit: 400 }} // Smooth timing
    />
  );
});


  const useStyles = makeStyles(() => ({
    root: {
      padding: '10px',
     
      borderRadius: 20,
  
        transition: '0.3s',
        backgroundImage: `url(${''})`, // Replace with the URL of your image
      backgroundSize: 'cover', // Cover the entire card with the image
      backgroundRepeat: 'no-repeat', // Do not repeat the image
      backgroundPosition: 'center', // Center the image
       
    },
    content: {
      padding: 10,
      spacing: 8,
    },
    brandCardHeader: {
      // Add styles for BrandCardHeader here
      fontSize: 25,
      fontWeight: 600,
      textAlign: 'left',
      margin: '0px' /* or a smaller value than what's currently set */
      
      
    },
    textInfoContent: {
      // Add styles for TextInfoContent here
    },
    button: {
      // Add styles for the button here
      backgroundColor: '#077336',
      color: 'white',
      borderRadius: 20,
      padding: 10,
      border: 'none',
      width: '40%',
      height: 40,
      marginTop: 10,
      '&:hover': {
        backgroundColor: '#077336',
      },
      
    },
  }));

const GET_PROD = gql`
  query Prod ($_id: ObjectId!)  {
  product (query: {_id: $_id}) {
   name
    price
    description1
    description2
    description3
    description4
    category
    imageurl
    imageurl1
    imageurl2
    imageurl3
    imageurl4
    farmer
    _id
   
  
  }
}
`; 

const UPDATE_CART_MUTATION = gql`
mutation UpdateOneCart($query: CartQueryInput, $set: CartUpdateInput!) {
  updateOneCart(query: $query, set: $set) {
    userId
    isPaid
    category
    items {
      productId
      quantity
      options {
        size
        color
        roast
      }
    }
  }
}
`;

const UPSERT_CART_MUTATION = gql`
mutation UpsertOneCart($query: CartQueryInput, $data: CartInsertInput!) {
  upsertOneCart(query: $query, data: $data) {
    userID
    cart {
      productId
      category
      quantity
      options {
        size
        color
      }
    }
  }
}

`;

// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    isPaid
    beanies
    name
    category
    quantity
    options {
      size
      color
    }
  }
}
`;

// GraphQL query to fetch flavor profiles
const GET_FLAVOR_PROFILES = gql`
  query GetFlavorProfiles {
    flavor_profiles {
    _id
      label
      embedding
    }
  }
`;

// GraphQL query to fetch coffee profiles
const GET_COFFEE_PROFILES = gql`
  query GetCoffeeProfiles {
    coffee_profiles {
      _id
      name
      image
      isavail
      flavor_notes
      composite_vector 
      productId
    }
  }
`;

const GET_PRODUCTS = gql`
  query Products($isavail: Boolean!) {
    products(query: { isavail: $isavail }) {
      Title
      _id
      description1
      description2
      description4
      imageurl
      countryName
      name
       prompt
      price
      farmer
      isavail
      category
    }
  }
`;

export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
      Koffie_story
    }
  }
`;



// Helper function: cosine similarity between two vectors.
function cosineSimilarity(vecA, vecB) {
  let dotProduct = 0, normA = 0, normB = 0;
  for (let i = 0; i < vecA.length; i++) {
    dotProduct += vecA[i] * vecB[i];
    normA += vecA[i] ** 2;
    normB += vecB[i] ** 2;
  }
  normA = Math.sqrt(normA);
  normB = Math.sqrt(normB);
  return normA && normB ? dotProduct / (normA * normB) : 0;
}

// Helper function: compute composite vector by averaging an array of vectors.
function computeComposite(vectors) {
  if (vectors.length === 0) return null;
  const sum = vectors[0].slice();
  for (let i = 1; i < vectors.length; i++) {
    for (let j = 0; j < sum.length; j++) {
      sum[j] += vectors[i][j];
    }
  }
  return sum.map(val => val / vectors.length);
}

export default function CoffeeRecommendations({ onAddToBag }) {
    const { user } = useContext(UserContext);
  const [userNotes, setUserNotes] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [clickedItems, setClickedItems] = useState([]);
  const [output, setOutput] = useState(null);
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [isLoading, setIsLoading] = useState(false);
    const [showDialog1, setShowDialog1] = useState(false);
    const [descriptionData1, setDescription] = useState(''); // New state for When
  const theme = useTheme();
  const [content, setOutput1] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [openRec , setOpenRec] = useState(true);
    const [showDescription, setShowDescription] = useState(false);
    const [selectedSize, setSelectedSize] = useState("");  // Default size
  const [selectedColor, setSelectedColor] = useState("");  // Default color
  const [selectedRoast, setSelectedRoast] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { itemsInBag, setItemsInBag } = useContext(BagContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const classes = useStyles();
  
  const goToCalc = () => {
    navigate('/calculator');
};


const variants = {
  enter: {
    opacity: 0,
    y: 20,
    scale: 0.9
  },
  center: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: {
      duration: 0.5,
      ease: "easeInOut"
    }
  }
};

  const images = [
    'https://storage.googleapis.com/app_darkendimg/newton.png',
    'https://storage.googleapis.com/app_darkendimg/assets/royal.png',
    'https://storage.googleapis.com/app_darkendimg/assets/madbean.png',
    'https://storage.googleapis.com/app_darkendimg/youngbean.png',
    'https://storage.googleapis.com/app_darkendimg/lilbeanie.png'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleClose1 = () => {
    setShowDialog1(false);
  }

    const handleCloseRec = () => {
        setOpenRec(false);
        navigate('/beanwatch'); // Navigate to the home page

    }

    const handleClickOpen = () => {
        setOpen(true);
        }
    const handleClose = () => {
        setOpen(false);
    }
    const navigate = useNavigate();

  // Query flavor profiles and coffee profiles using Apollo Client.
  const { data: flavorData, loading: flavorLoading, error: flavorError } = useQuery(GET_FLAVOR_PROFILES);
  const { data: coffeeData, loading: coffeeLoading, error: coffeeError } = useQuery(GET_COFFEE_PROFILES);
    const { data: productData, loading: productLoading, error: productError } = useQuery(GET_PRODUCTS, {
        variables: { isavail: true },
    });
    const [upsertCart, ] = useMutation(UPSERT_CART_MUTATION);
    const [updateCart, ] = useMutation(UPDATE_CART_MUTATION);
    const [insertCart, ] = useMutation(INSERT_CART_MUTATION);

    //Get the productId from coffeeData
    const productId = coffeeData?.coffee_profiles[0]?.productId || null;

    const _id = productId;
    


    // Remove preset variables from the lazy query
const [loadDescription, { data: descriptionData, loading: descLoading, error: descError }] = useLazyQuery(GET_PROD, {
  fetchPolicy: "network-only",
});

useEffect(() => {
    // If coffee data is available and we haven't warmed up yet…
    if (coffeeData && coffeeData.coffee_profiles && coffeeData.coffee_profiles.length > 0) {
      const initialId = coffeeData.coffee_profiles[0].productId;
      // Warm up the lazy query by making a call (you can ignore the result)
      loadDescription({ variables: { _id: initialId } });
    }
  }, [coffeeData]);
  

  //Koffie Wheel

  const ChartData = [
    {
      maxdepth: 6,
      insidetextorientation: 'radial',
      textposition: 'inside',
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"], 
      marker: {
        line: { width: 2 },
        colors: [
          "#6F4E37",    // Coffee (center)
          "#E17033",    // Aroma
          "#D78B42",    // Brown Sugary
          "#6A4C3F",    // Dry Distillation
          "#88B14B",    // Taste
          "#D9DB4D",    // Sour
          "#E2E478",    // Soury
          "#ECA037",    // Enzymatic
          "#DD7272",    // Sweet
          "#A9C7C1",    // Salt
          "#63AAAC",    // Bitter
          "#83694D",    // Carbony
          "#926547",    // Spicy
          "#A96F44",    // Resinous
          "#A18F79",    // Ashey
          "#8D7960",    // Smokey
          "#7E6856",    // Burnt
          "#715B49",    // Charred
          "#624D3E",    // Tarry
          "#5B4638",    // Pipe Tobacco
          "#AB6843",    // Warming
          "#C17E58",    // Pungent
          "#CC906A",    // Thyme
          "#D7A07B",    // Clove
          "#E0B18B",    // Cedar
          "#DEA76D",    // Pepper
          "#B99F93",    // Tupeny
          "#9E8B7F",    // Medicinal
          "#A28E88",    // Ceniolic
          "#B29B95",    // Camphoric
          "#B8A59D",    // Piney
          "#91638F",    // Blackcurrant-like
          "#AB5F29",    // Chocolatey
          "#CF843D",    // Caramelly
          "#CB7E35",    // Nutty
          "#E8B955",    // Vanilla-like
          "#BE6E44",    // Chocolate-like
          "#DC9A51",    // Syrup-like
          "#E4AA6A",    // Candy-like
          "#D1B280",    // Malt-like
          "#D8A369",    // Nut-like
          "#E3BF92",    // Butter
          "#E9CDA5",    // Swiss
          "#A5522A",    // Dark Chocolate
          "#944A27",    // Bakers
          "#D68F45",    // Maple Syrup
          "#E2B962",    // Honey
          "#C37E3D",    // Roasted Almond
          "#C98B47",    // Roasted Hazlenut
          "#D5D98F",    // Herby
          "#DD6F5F",    // Fruity
          "#E789A9",    // Flowery
          "#97B982",    // Leguminous
          "#A5B86E",    // Alliaceous
          "#C86586",    // Berry-like
          "#E6904E",    // Citrus
          "#D97997",    // Fragrant
          "#E8ADC3",    // Floral
          "#B7BE72",    // Onion
          "#C5CA85",    // Garlic
          "#ACD08D",    // Cucumber
          "#92C385",    // Garden Peas
          "#C76B96",    // Blackberry
          "#F4A86D",    // Apricot
          "#FAE66A",    // Lemon
          "#9BD26C",    // Apple
          "#CCA26E",    // Coriander Seeds
          "#CEAE81",    // Cardamon Caraway
          "#EEA3B7",    // Tea Roase
          "#E998B1",    // Coffee Blossom
          "#F2EE5F",    // Acidity
          "#F7E37F",    // Mellow
          // Continue similar colors for remaining labels as needed...
        ],
      }
    }
  ];
  

  const flavorColorMap = {};
ChartData[0].labels.forEach((label, index) => {
  flavorColorMap[label] = ChartData[0].marker.colors[index];
});

  const layout = {
    width: !isMobile ? 600 : 400,
    margin: {l: 0, r: 0, b: 0, t: 0}, 
    showlegend: false,
    bordercolor: "#000",
    paper_bgcolor: theme.palette.background.default,
    fontSize: 12,
    height: 400,
  
  };
  const config = {
    displayModeBar: false,
    modeBarButtonsToRemove: ['sendDataToCloud'],  // removes the 'Edit in Chart Studio' button
    displaylogo: false,  // hides the Plotly logo
    responsive: true,  // makes the chart responsive to window size
    alignProperty: 'center',
    align: 'center',
  
  }; 
  

  const kupakoffie = () => {
    return {
      "name": "Barista",
      "description": "Give info based on available data.",
      "parameters": {
        "type": "object",
        "properties": {
          "Title": {
            "type": "string",
            "description": "Title of Subject"
          },
          "price": {
            "type": "string",
            "description": "do not output dollar sign. Integer Only."
          },
          "description": {
            "type": "string",
            "description": "Tell a short tale of the events in what happened."
          },
          "productID": {
            "type": "string",
            "description": "The product ID"
          },
  
            "primaryImage": 
            {"type": "string",
            "description": "The url of the primary image"
          
          },
          "imageurl":
          {"type": "string",
          "description": "The URL of userimage"
        },
       
  
        
          "When": 
            {"type": "string",
            "description": "When is this available"
          
          },
  
          "userID":
          {"type": "string",
          "description": "The user ID"
        }, 
        "imageurl1": {
          "type": "string",
          "description": "The URL of the first image"
        },
        "imageurl2": {
          "type": "string",
          "description": "The URL of the second image"
        },
        "imageurl3": {
          "type": "string",
          "description": "The URL of the third image"
        },
        "imageurl4": {
          "type": "string",
          "description": "The URL of the fourth image"
        },
        "sales_pitch": {
          "type": "string",
          "description": "A compelling sales pitch based on the product description."
        }
        
          
        },
        
        "required": ["name", "Title", "description",  "primaryImage", "When",   "price", "productID", "userID", "imageurl", "imageurl1", "imageurl2", "imageurl3", "imageurl4", "sales_pitch"]
      }
    };
  };
    
  
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15, // clearly sets delay between children
      },
    },
  };
  
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delay: index * 0.2, // clearly staggered by index
      },
    }),
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };
  
  
  
  // Inside your component render/map function:
  
  
  const handleClick2 = () => {
    setIsClicked(true);
    // Reset the animation state after a short delay
    setTimeout(() => setIsClicked(false), 300);
  };
  
  const handleClick1 = (data) => {
    if (data.points && data.points.length > 0) {
        const label = data.points[0].label;
        
        setClickedItems([...clickedItems, { label, }]);
        setButtonClicked(true);
    }
};
console.log(clickedItems);

const clearClickedItems = () => {
  setClickedItems([]);  // Reset clickedItems to an empty array
};

const [isButtonClicked, setButtonClicked] = useState(false);

  // Handler for input changes.
  const handleInputChange = (e) => {
    setUserNotes(e.target.value);
  };

  // Handler for chip reset
  const handleReset = () => {
    setClickedItems([]);
  };

  // Handler for clicks on the chart.
  const handleClick = (event) => {
    // Extract the clicked label from the Plotly event.
    const clickedLabel = event.points && event.points[0] && event.points[0].label;
    if (clickedLabel) {
      setClickedItems(prevItems => {
        // Only add if not already present.
        if (!prevItems.includes(clickedLabel)) {
          return [...prevItems, clickedLabel];
        }
        return prevItems;
      });
    }
  };

  // When user submits, compute recommendations.
  const handleSubmit = () => {
    if (!flavorData || !flavorData.flavor_profiles || !coffeeData || !coffeeData.coffee_profiles) {
      console.warn("Data is not available yet.");
      return;
    }
  
    const flavorDict = {};
    flavorData.flavor_profiles.forEach(profile => {
      flavorDict[profile.label] = profile.embedding;
    });
  
    const notes = clickedItems;
    const userVectors = notes.map(note => flavorDict[note]).filter(v => v);
  
    if (userVectors.length === 0) {
      console.warn('No valid flavor embeddings found.');
      return;
    }
  
    const userComposite = computeComposite(userVectors);
  
    // Compute similarity and separate by availability
    const available = [];
    const unavailable = [];
  
    coffeeData.coffee_profiles.forEach(coffee => {
      const similarity = cosineSimilarity(userComposite, coffee.composite_vector);
      const coffeeRec = {
        name: coffee.name,
        similarity,
        image: coffee.image,
        flavor_notes: coffee.flavor_notes,
        productId: coffee.productId,
        isavail: coffee.isavail || false
      };
  
      if (coffeeRec.isavail) {
        available.push(coffeeRec);
      } else {
        unavailable.push(coffeeRec);
      }
    });
  
    // Sort both arrays
    available.sort((a, b) => b.similarity - a.similarity);
    unavailable.sort((a, b) => b.similarity - a.similarity);
  
    // Combine arrays: available first, unavailable second
    const sortedRecs = [...available, ...unavailable];
  
    setRecommendations(sortedRecs);
    handleClickOpen();
  };
  
  const handleRequestBean = (rec) => {
    // Example logic for requesting beans
    console.log(`Bean request submitted for: ${rec.name}`);
    
    // Implement further logic such as opening a form, sending API call, etc.
    // For example:
    alert(`Request sent for ${rec.name}! We'll notify you when it's available.`);
  };

  
  const handleShowDescription = (_id) => {
    if (_id) {
        loadDescription({ variables: { _id } });
    } else {
        console.warn("No product ID found");
    }
};
  
  // When lazy query returns data, update your state
useEffect(() => {
    if (descriptionData && descriptionData.product) {
      setDescription(descriptionData);
    }
  }, [descriptionData]);
  
  // In your component's render, conditionally display product info:
  if (descLoading) return <p><Progress /></p>;
  if (descError) return <p>Error: {descError.message}</p>;
  
  const product = descriptionData?.product
    ? {
        id: descriptionData.product._id,
        name: descriptionData.product.name,
        price: descriptionData.product.price,
        description1: descriptionData.product.description1,
        description2: descriptionData.product.description2,
        description3: descriptionData.product.description3,
        description4: descriptionData.product.description4,
        category: descriptionData.product.category,
        imageurl: descriptionData.product.imageurl,
        imageurl1: descriptionData.product.imageurl1,
        imageurl2: descriptionData.product.imageurl2,
        imageurl3: descriptionData.product.imageurl3,
        imageurl4: descriptionData.product.imageurl4,
        farmer: descriptionData.product.farmer,
      }
    : null;
  
    const handleSubmit2 = async (product) => {
        setIsLoading(true);
        // Use the productId field from the coffee profile since that is the product’s id.
        const selectedId = product.productId || product._id;
        if (!selectedId) {
          console.warn("No valid product ID available");
          setIsLoading(false);
          return;
        }
        try {
          // Await the lazy query with the correct variable.
          const result = await loadDescription({ variables: { _id: selectedId } });
          if (result.data && result.data.product) {
            await runAgentWithProduct(result.data.product);
          } else {
            console.warn("No product data returned for ID:", selectedId);
          }
        } catch (err) {
          console.error("Error in handleSubmit2:", err);
        } finally {
          setIsLoading(false);
        }
      };
      

      const runAgentWithProduct = async (productData) => {
        try {
          const coffeeFunction = kupakoffie(); // Your function that sets up the OpenAI API call
          const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${"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"}`,
            },
            body: JSON.stringify({
              messages: [
                {
                  role: "system",
                  content: "You are the world's best sales agent with a great sense of humor. Based on the Item information, sell it to get Rich. Do not output dollar sign on price, integer only :)"
                },
                {
                  role: "user",
                  content: `Here is a product description: "${JSON.stringify(productData)}". Your task is to turn it into an engaging and persuasive sales pitch. Highlight its unique features and why customers absolutely need it! Keep it fun and compelling.
                  
      Important: Prices are listed in cents, so be sure to divide by 100 and format them to two decimal places. Also, include a unique title for the product.`
                }
              ],
              temperature: 0.5,
              model: "gpt-4o",
              functions: [coffeeFunction],
              function_call: {
                name: "Barista",
                arguments: JSON.stringify(productData),
              },
            }),
          });
          const resultData = await response.json();
          let contentData;
          if (resultData.choices && resultData.choices[0] && resultData.choices[0].message) {
            if (resultData.choices[0].message.content) {
              contentData = resultData.choices[0].message.content;
            } else if (resultData.choices[0].message.function_call) {
              contentData = JSON.parse(resultData.choices[0].message.function_call.arguments);
            }
          }
          setOutput1(contentData);
          setShowDialog1(true);
          console.log('OpenAI API result:', contentData);
        } catch (error) {
          console.error("Error calling OpenAI API:", error);
        }
      };

      

  const handleAddToCart3 = async (product) => { 
    handleClick2();
    const beaniesToAdd = product.category === 'Koffie'  ? 4000 : 0;
    const cartData = {
      query: { userID: user.id },  // Assuming user.id is securely fetched
      data: {
        userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
        productId: product.id,
        quantity: 1,
        isPaid: 'false',
        name: product.name,
        beanies: beaniesToAdd,
        category: product.category,
        price: product.price,
        iskoffieland: true,
        imageurl: product.imageurl,  // Adjust this according to your schema
        options: {  // Options need to be nested within each cart item
          size: selectedSize,  // Use selected size
            color: selectedColor , // Use selected color
            roast: selectedRoast
        }
      }
    };
  
    try {
      await insertCart({
        variables: cartData
      });
     
      onAddToBag(product);  // UI feedback action
      setSnackbarOpen(true); // Show the snackbar
      //console log setsnackbaropen
        console.log("Snackbar Opened");
      setItemsInBag(itemsInBag + 1); // Update items in bag context
    } catch (error) {
      console.error("Error inserting cart:", error);
      // Optionally handle error in the UI
    }
  };
  

  if (flavorLoading || coffeeLoading) return <div><Progress /></div>;
  if (flavorError || coffeeError) return <div>Error loading data.</div>;

  return (
    <Dialog
        open={openRec}
        onClose={handleCloseRec}
        fullScreen={isMobile}
        PaperProps={{
            sx: {
              p: 0, // Remove default padding
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              
            },
          }}
          >
            <Box sx={{ padding: 2 }}>
        <IconButton onClick={handleCloseRec} sx={{ position: 'absolute', top: 0, left: 0 , padding: 2}}>
            <ArrowBackIos />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ fontWeight: 800, textAlign: 'center', marginBottom: 0 }}>
            Coffee Recommendations
        </Typography>
        
        </Box>
            <Box sx={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 3, backgroundColor: theme.palette.background.default }}>
      
        
        
        <Plot
    data={ChartData}
    layout={layout}
    onHover={handleClick}
    showlegend={false}
    config={config}
    
  />
        </Box>
    
    {/* Display default box if no selection is made */}
    {clickedItems.length === 0 ? (
        <Card sx={{ boxShadow: 'none',padding: 2, marginTop: 0, borderRadius: 3, backgroundColor: theme.palette.background.paper }}>
          <CardContent sx={{ textAlign: 'center', padding: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
             
            <Typography variant="h6">Start by selecting your flavor preferences!</Typography>
            <img
                src={images[currentImageIndex]}
                alt="Rotating Coffee"
                style={{ width: '100px', height: '100px', borderRadius: 3 , position: 'absolute', top: -50,  right: 30, bottom: 0, margin: 'auto', display: 'block'}}
              />
            </Box>
            <Typography variant="body2" sx={{ marginTop: 1, color: 'gray' }}>
              Click on any flavor from the chart above to begin.
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button onClick={goToCalc} variant="contained" sx={{ backgroundColor: '#077336', color: 'white', borderColor: 'black' }}>
              Bean Counter
              </Button>
          </Box>
        </Card>
      ) : (
        <Card sx={{ boxShadow: 'none', marginTop: 3, padding: 2, borderRadius: 3, backgroundColor: theme.palette.background.paper }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>Your selected flavor profile:</Typography>
            <List sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1 }}>
            {clickedItems.map((item, index, color) => (
                <Chip key={index} label={item} 
                sx={{ backgroundColor: flavorColorMap[item] || 'grey', color: 'white' }}
                
                />
              ))}
            </List>
            <Button onClick={handleReset} variant="outlined" size="small" sx={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}>
              Reset Selection
            </Button>
          </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 2 }}>
          <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#077336', color: 'white', borderColor: 'black' }}>
        Get Recommendations
      </Button>
          </Box>
        </Card>
      )}
     
    <Dialog 
     open={open}
    onClose={handleClose}
    fullScreen={isMobile}
    maxWidth="sm"
    PaperProps={{
      style: {
        backgroundColor: theme.palette.background.default,
        
      },
    }}
    >
      <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    p: 2,
  }}
>
  <Typography variant="h5" component="h1">
    Coffee Recommendation
  </Typography>
  {/* Other components or content below */}
</Box>

       {/* AnimatePresence + motion.ul for a staggered effect */}
       <AnimatePresence>
       
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {recommendations.map((rec, index) => {
          // Generate random offset values for the shuffle effect:
          const randomOffset = {
            offsetX: Math.random() * 200 - 100,
            offsetY: Math.random() * 200 - 100,
            // rotate removed clearly here
          };
          

          return (
            <Grid item xs={6} sm={6} md={4} key={index}>
              <motion.div
          custom={index} // pass index clearly for stagger
          variants={itemVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: 2,
                    position: 'relative',
                  }}
                >
                  {/* Run Agent Label */}
                  {/* Availability-based banner */}
<Box
  onClick={() => rec.isavail ? handleSubmit2(rec) : handleRequestBean(rec)}
  sx={{
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: rec.isavail ? '#ffc107' : '#ebecf0',
    color: 'black',
    cursor: 'pointer',
    px: 1,
    py: 0.5,
    borderRadius: 2,
    boxShadow: 1,
    transform: 'rotate(3deg)',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: -8,
      top: 0,
      borderLeft: '8px solid',
      borderLeftColor: rec.isavail ? '#ffc107' : '#ebecf0',
      borderTop: '12px solid transparent',
      borderBottom: '12px solid transparent',
    },
  }}
>
  <Typography variant="caption">
    {rec.isavail ? '4oz more + 4k Tokens' : 'Request This Bean'}
  </Typography>
</Box>


                  <CardMedia
                    component="img"
                    height="200"
                    image={rec.image}
                    alt={rec.name}
                    sx={{ objectFit: 'cover' }}
                  />

                  <CardContent>
                    {/* Coffee Name */}
                    <Typography variant="body1" gutterBottom fontWeight={800}>
                      {rec.name}
                    </Typography>

                    {/* Flavor notes */}
                    {rec.flavor_notes && (
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        {rec.flavor_notes.join(', ')}
                      </Typography>
                    )}

                    {/* Price, tokens, and add-to-cart */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 1,
                      }}
                    >
                    

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip onClick={() => handleSubmit2(rec)} label="Run Agent" size="small" sx={{ backgroundColor: '#077336', color: '#fff', marginTop: 1 }} />
                       
                      </Box>
                    </Box>

                    {/* Similarity score */}
                    <Typography variant="caption" sx={{ display: 'block', marginTop: 1 }}>
                      Similarity: {rec.similarity.toFixed(2)}
                    </Typography>
                    
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  
</AnimatePresence>

      {!isMobile && (
           <Box>
           {isLoading ? (
       <Dialog   open={isLoading} onClose={() => setIsLoading(false)}>
        <DialogContent sx={{ padding: 2, textAlign: 'center', mt: 10, backgroundColor: "transparent" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding: 5 }}>
            <FactGen />
          </Box>
      
        </DialogContent>
        </Dialog>
    ) : (
      <>
           
      
          <div >
          {showDialog1 && (
        <Dialog
          
          open={showDialog1}
          onClose={() => setShowDialog1(false)}
          TransitionComponent={Transition}
        >    
         <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Item added, go to cart to complete transaction
  </Alert>
</Snackbar>
            
           
                  {content && (
         
       <Box sx={{ display: 'flex',  }}>
        <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Item added, go to cart to complete transaction
  </Alert>
</Snackbar>
        <Card  sx={{ width: '100%', height: '100%', borderRadius: 0, padding: 1,  }}>
      
        <Box sx={{ position: 'relative', width: '100%' }}>
  <CardMedia
    component="img"
    sx={{ width: '100%', height: 250, borderRadius: 0, objectFit: 'cover' }}
    image={content.primaryImage}
    alt="Image description"
  />
  <IconButton 
    onClick={handleClose1}
    sx={{
      position: 'absolute',
      top: 8,
      left: 8,
      backgroundColor: 'rgba(255,255,255,0.7)',
      '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' }
    }}
  >
    <Clear />
  </IconButton>
</Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>    
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <UserImage userID={content.userID}  />

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <FarmerImage UserID={content.userID}  />
          </Box>  
          </Box> 
           <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
           
                  
               
      
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
            <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)'}}>
              {content.Title}
            </Typography>
           
            </Box>
                <Typography variant="h6" sx={{
                  fontWeight: 300,
                  color: 'text.secondary',
                  textAlign: 'left',
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingBottom: 2,
                }}>
                 
                  {content.sales_pitch}
                </Typography>
                
       
        
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2 }}>
       <Link to="/bag" style={{ textDecoration: 'none' }}>
                    <Chip
                        label={`Bag (${itemsInBag.length})`}
                        className={classes.transparentChip}
                        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png' alt="Congo" />}
                    />
                </Link>
       <CardActionArea>
       
       <CardActions sx={{ justifyContent: 'flex-end'}} >
      <Typography variant='h4'sx={{fontWeight: 600, paddingRight: 2, justifyContent: 'flex-start' }}>${(content.price / 100).toFixed(2)}</Typography> 
      <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small"  sx={{ 
                                                            display: 'inline-flex', 
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            transition: 'transform 0.3s, color 0.3s',
                                                            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                                                            color: isClicked ? '#0a0' : '#077336',
                                                            cursor: 'pointer',
                                                        }}/>
  
            
        </CardActions>
        </CardActionArea>
        </Box>
                </CardContent>
            
        
        
      </Card>
      </Box>
     
            )}	
            </Dialog>
      )}
          </div>  
         
      </>

    )}            
           </Box>
        )}
        {isMobile && (
           <Box>
           {isLoading ? (
       <Dialog  fullScreen open={isLoading} onClose={() => setIsLoading(false)}>
        <DialogContent sx={{ padding: 2, textAlign: 'center', mt: 10, backgroundColor: "transparent" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding: 5 }}>
            <FactGen />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
      <AnimatePresence mode="wait">
        <motion.img
          key={currentImageIndex}
          src={images[currentImageIndex]}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          alt="Coffee"
          style={{ width: "100%", objectFit: "cover" }}
        />
      </AnimatePresence>
      
    </Box>
        </DialogContent>
        </Dialog>
    ) : (
      <>
           
      
          <div >
          {showDialog1 && (
        <Dialog
          fullScreen
          open={showDialog1}
          onClose={() => setShowDialog1(false)}
          TransitionComponent={Transition}
        >   
        
            
           
                  {content && (
         
       <Box sx={{ display: 'flex',  }}>
         
        <Card  sx={{ width: '100%', height: '100%', borderRadius: 0, padding: 0,  }}>
      
        <Box sx={{ position: 'relative', width: '100%', zIndex: 9999 }}>
        <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Item added, go to cart to complete transaction
  </Alert>
</Snackbar> 
  <CardMedia
    component="img"
    sx={{ width: '100%', height: 250, borderRadius: 0, objectFit: 'cover' }}
    image={content.primaryImage}
    alt="Image description"
  />
  <IconButton 
    onClick={handleClose1}
    sx={{
      position: 'absolute',
      top: 8,
      left: 8,
      backgroundColor: 'rgba(255,255,255,0.7)',
      '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' }
    }}
  >
    <Clear />
  </IconButton>
</Box>


        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>    
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <UserImage userID={content.userID}  />

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <FarmerImage UserID={content.userID}  />
          </Box>  
          </Box> 
          
           <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
           
                  
               
       <Typography variant='h5' >{content.Who}</Typography>
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
            <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, }}>
              {content.Title}
            </Typography>
           
            </Box>
                <Typography variant="h6" sx={{
                  fontWeight: 300,
                  color: 'text.secondary',
                  textAlign: 'left',
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingBottom: 2,
                }}>
                 
                  {content.sales_pitch}
                </Typography>
                
       
        
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2 }}>
       <Link to="/bag" style={{ textDecoration: 'none' }}>
                    <Chip
                        label={`Bag (${itemsInBag.length})`}
                        className={classes.transparentChip}
                        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png' alt="Congo" />}
                    />
                </Link>
       <CardActionArea>
       
       <CardActions sx={{ justifyContent: 'flex-end'}} >
      <Typography variant='h6'sx={{fontWeight: 600, paddingRight: 2, justifyContent: 'flex-start' }}>${(content.price / 100).toFixed(2)}</Typography> 
      <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small"  sx={{ 
                                                            display: 'inline-flex', 
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            transition: 'transform 0.3s, color 0.3s',
                                                            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                                                            color: isClicked ? '#0a0' : '#077336',
                                                            cursor: 'pointer',
                                                        }}/>
  
            
        </CardActions>
        </CardActionArea>
        </Box>
                </CardContent>
            
        
        
      </Card>
      </Box>
     
            )}	
            </Dialog>
      )}
          </div>  
         
      </>

    )}            
           </Box>
        )}
        </Dialog>
        
  </Dialog>

  
  );
  
}
// Component to fetch and display </></> image
function UserImage({ userID }) {
    const { loading, error, data } = useQuery(GET_ROASTERIES, {
        variables: { _id: userID },
    });
  
    if (loading) return <Avatar src="https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp" alt="user" style={{ width: 60, height: 60,  }} />;
    if (error) return <Card sx={{ boxShadow: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderBlock: 1,  borderRadius: 5, }}>
          
    <img src="https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp" alt="user" style={{ width: 40, height: 40, borderRadius: 50,  }} />
      <CardContent sx={{padding: 0, margin: 0, }}>
        <Typography variant="caption" fontWeight={400}>
          Coming Soon - Roast Farmer
        </Typography>
        </CardContent>
        </Card>;
    const userimage = data.userprofs[0]?.imageurl || 'Unknown';
    const username = data.userprofs[0]?.Nickname || 'Unknown';
    const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';
  
  
  
    return (
      <>
      
      <Box display="flex" >
      
        <Avatar src={userimage} alt={username} style={{ width: 60, height: 60, borderRadius: 50 }} />
       
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
        <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
          {username}
        </Typography>
       
        <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
          {userstory}
        </Typography>
        </Box>
      </Box>
      
      </>
      
    );
            
  }
  
  // Component to fetch and display </></> Farmer image
  function FarmerImage({ userID }) {
    const { loading, error, data } = useQuery(GET_ROASTERIES, {
        variables: { _id: userID, },
    });
    console.log(data);
  
    if (loading) return <img src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="user" style={{ width: 60, height: 60, borderRadius: 50 }} />;
    if (error) return <Card sx={{boxShadow: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1,  borderRadius: 5, }}>
           
    <img src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="user" style={{ width: 40, height: 40, borderRadius: 50 }} />
      <CardContent>
        <Typography variant="caption" fontWeight={400}>
          Coming Soon - Farmer
        </Typography>
        </CardContent>
        </Card>;
  
    const farmerimage = data.userprofs?.imageurl || 'Unknown';
    const farmername = data.userprofs?.Nickname || 'Unknown';
    const farmerstory = data.userprofs?.Koffie_story || 'Unknown';
  
    
  
    return (
      <>
      
      <Box display="flex" >
      
        <Avatar src={farmerimage} alt={farmername} style={{ width: 60, height: 60, borderRadius: 50 }} />
       
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
        <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
          {farmername}
        </Typography>
       
        <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
          {farmerstory}
        </Typography>
        </Box>
      </Box>
      
      </>
      
    );
    }  

