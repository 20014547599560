import React, { useState, useEffect } from 'react';
import { Button, Card, Grid, TextField, Typography, RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_PROF, GET_USER } from '../../../context/gqllogics';

import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';



const CashAppIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/assets_chashapp.png" alt="beans" style={{ width: 40, height: 40, background: 'none' }} />
    );
  };
  
  const ZelleIcon = () => {
    return (
      <img src="https://storage.googleapis.com/app_darkendimg/assets/zelleasset.png" alt="beans" style={{ width: 40, height: 40, background: 'none' }} />
    );
  }; 

const PaymentOptions = () => {
    const { user } = useContext(UserContext);
    const [payout, setPayout] = useState('');
  const [cashappid, setCashAppId] = useState('');
  const [zelleid, setZelleId] = useState('');

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { _id: user.id },
    skip: !user.id,
  });

    useEffect(() => {
        if (userData && userData.userprof) {
          setCashAppId(userData.userprof.cashappid || '');
          setZelleId(userData.userprof.zelleid || '');
          setPayout(userData.userprof.payout || '');
        }
      }, [userData]);
    
      const [updateUserProf] = useMutation(UPDATE_USER_PROF);

    const handleSave = async () => {
        const updatedFields = {};
    
        if (cashappid) {
          updatedFields.cashappid = cashappid;
        }
        if (zelleid) {
          updatedFields.zelleid = zelleid;
        }
        if (payout) {
          updatedFields.payout = payout;
        }
    
        try {
          await updateUserProf({
            variables: {
              query: { _id: user.id },
              set: updatedFields,
            },
          });
          
        } catch (error) {
          console.error(error);
        }
      };
    
    
      const isSaveDisabled = !cashappid && !zelleid;

    return (
        <div>
            <h2>Update Payout Options</h2>
            <Card sx={{ boxShadow: 0, borderRadius: 0, paddingBottom: 0, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 700, mt: 0, ml: 2 }}>Payout</Typography>
              <Typography variant="body1" sx={{ ml: 2, mt: 2 }}>We currently pay out to CashApp and Zelle.</Typography>
              <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>Stay tuned for more payment options.</Typography>

              <Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
                <Grid item xs={12}>
                  <TextField
                  sx={{ paddingLeft: 0}}
                    label="CashApp ID"
                    size='small'
                    value={cashappid}
                    onChange={(e) => setCashAppId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start" sx={{backgroundColor: 'none', paddingLeft: 0}}>
                          <CashAppIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '0px',
                          marginBottom: 0,
                          
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  sx={{ paddingLeft: 0}}
                    label="Zelle ID"
                    size='small'
                    value={zelleid}
                    onChange={(e) => setZelleId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start" sx={{backgroundColor: 'none', paddingLeft: 0}}>
                          <ZelleIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '0px',
                          marginBottom: 0,
                          
                          paddingLeft: '0px'
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>Select Default Payout Method:</Typography>
                  <RadioGroup
                    aria-label="payout"
                    name="payout"
                    value={payout}
                    onChange={(e) => setPayout(e.target.value)}
                  >
                    <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                    <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                    sx={{ mt: 1, backgroundColor: '#077336', color: 'white', fontWeight: 600, borderRadius: 20, border: 'solid 0px #077336', alignSelf: 'flex-end'}}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Card>
          
        </div>
    );
};

export default PaymentOptions;
