import React, { useState } from 'react';
import axios from 'axios';
import PaymentOptions from '../components/finance/prods/paymentoptions';

const DeepSeekAI = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const API_KEY = 'sk-6626be0fa0444bc8931a5de57753abf5'; // DeepSeek API key
  const API_URL = 'https://api.deepseek.com/v1/chat/completions'; // Confirm this endpoint!

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await axios.post(
        API_URL,
        {
          model: 'deepseek-chat', // Verify model name with DeepSeek docs
          messages: [{ role: 'user', content: input }],
        },
        {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Extract response based on DeepSeek's response structure
      const responseContent = result.data.choices[0].message.content;
      setResponse(responseContent);
    } catch (error) {
      console.error('DeepSeek API Error:', error.response?.data || error.message);
      setResponse('Failed to fetch response from DeepSeek.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>DeepSeek AI</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter your input"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Submit'}
        </button>
      </form>

      {response && (
        <div>
          <h2>Response:</h2>
          <pre>{response}</pre>
        </div>
      )}
      <PaymentOptions />
    </div>
  );
};

export default DeepSeekAI;