import React, { useState, useContext, useEffect  } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import { useBag } from '../../context/BagContext';
import BagIcon from '../finance/BagIcon'; // Ensure this is the correct path
import koffielogo from '../../images/kcoin.png';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import { makeStyles } from '@mui/styles';
import Barista from '../tests/barista';
import BeanBaggy from '../finance/BeanBag';
import PaymentIcon from '@mui/icons-material/Payment';
import eth from '../../images/eth.svg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Add, Close, SearchOffRounded, SearchOutlined } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PostAndUploadComponent from '../posts/post';
import { Button, Drawer, Icon } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_POSTS } from '../posts/dailypost';
import Progress from '../mods/progress';
import IconButton from '@mui/material/IconButton';
import QueryShopPage from '../../pages/search';
import SearchIcon from '@mui/icons-material/Search';
import Explorer from '../../pages/explorer';
import CoffeeRecommendations from '../../pages/recommend';

const BeanIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/greybean_icon.png' alt="koffie" width={20} />
  );
};
const CoffeeIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/whitebean_icon.png' alt="koffie" width={20} />
  );
};

const useStyles = makeStyles((theme) => ({
  spreadAnimation: {
    // Define the animation keyframes directly within the style
    animation: `$spreadOut 0.5s ease`,
  },
  '@keyframes spreadOut': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: ' 0 20px 0px ', // Adjust border radius as needed
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'black', 
    border: 'none',
    
    fontSize: 20,
    fontWeight: 700,
  },
  // Additional styles can be added here
}));

const EthIcon = () => {
  return (
    <img src={eth} alt="koffie" width={20} />
  );
};


export default function Toe() {
  const [value, setValue] = useState('recents');
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { itemCount } = useBag();
  const classes = useStyles();
  const theme = useTheme();  // Access the current theme (dark/light)
  const isDarkMode = theme.palette.mode === 'dark';  // Check if dark mode is enabled
  const [fetchPosts, { loading, error, data }] = useLazyQuery(GET_ALL_POSTS, {
    fetchPolicy: 'network-only', // Ensures a fresh fetch
  }); // Ensure you have the correct query imported
  const [postSubmitted, setPostSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
 const [openFeedback, setOpenFeedback] = useState(false);

const handleFeedback = () => {
  setOpenFeedback(true);
};

const handleCloseFeedback = () => {
  setOpenFeedback(false);
};

  const handleLogoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setExpanded(true);
  };

  const handleCloseModal = () => {
    setExpanded(false);
  };
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (postSubmitted) {
      // Close the modal after post is submitted
      setExpanded(false);
      
      setPostSubmitted(false); // Reset for next time
    }
  }, [postSubmitted]);

  const handleClosed = () => {
    setAnchorEl(null);
  };



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const thelogo = (
    <img
      src={koffielogo}
      alt="logo"
      width="60"
      height="60"
      onClick={handleLogoClick}
    />
  );

  // Loading
  if (loading) return <div><Progress/></div>;
  return (
    <>
      {user && (
       <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,  zIndex: 1300 , border: 'none'}}>
       <BottomNavigation sx={{ mb: 1, borderRadius: 0, width: '100%' }} value={value} onChange={handleChange}>
        <BottomNavigationAction
  component={Link}
  to="/post"
  value="ai"
  icon={
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <HomeOutlinedIcon color="inherit" />
      <Typography variant="caption" color="text.secondary">
        Home
      </Typography>
    </Box>
  }
  onClick={() => fetchPosts()} // Trigger the lazy query on click
/>
         <BottomNavigationAction
           component={Link}
           to="/home"
           value="user-post"
           icon={
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Diversity3OutlinedIcon color="inherit" />
              <Typography variant="caption" color="text.secondary" sx={{ }}>
                Koffieland
              </Typography>
            </Box>
           }
           sx={{ }}
         />
         <BottomNavigationAction
           component={Link}
            to="/koffievector"
           value="market"
           icon={
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {isDarkMode ? <CoffeeIcon /> : <BeanIcon />}
      <Typography variant="caption" color="text.secondary">
        Koffie
      </Typography>
    </Box>
          }
         />
         
       
         
      <BottomNavigationAction
  component={Link}
  to="/beanwatch"
  value="xplore"
  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} // Stack the icon and text vertically
  icon={
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AttachMoneyIcon color="inherit" />
      <Typography variant="caption" color="text.secondary" sx={{ }}>
        Bean Club
      </Typography>
    </Box>
  }
/>
         
           <BottomNavigationAction
             component={Link}
             paddingTop="0"
             to="/shoptabs"
             value="folder"
             icon={
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PaymentIcon color="inherit" />
                <Typography variant="caption" color="text.secondary" sx={{ }}>
                  Market
                </Typography>
              </Box>
             }
             sx={{ }}
           >
            
           </BottomNavigationAction>
         
       </BottomNavigation>
     </Paper>
     
      )}
    </>
  );
}
