import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';

function Value() {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem', padding: 2 }}>
        <Card sx={{ width: '100%', borderRadius: 10 }}>
          <CardContent>
            <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary' }}>
              Value Add
            </Typography>
            <Typography variant='body2' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary' }}>
              Add value to your coffee
            </Typography>
          </CardContent>
          <Grid container spacing={1} sx={{ padding: 2 }}>
            {/* Image and Video Mosaic */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {/* Image */}
                <Box sx={{ width: '48%', marginBottom: '1rem' }}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/app_darkendimg/assets/the_gap.jpg"
                    alt="Image 1"
                  />
                </Box>
                <Box sx={{ width: '48%', marginBottom: '1rem' }}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/app_darkendimg/assets/the_gap.jpg"
                    alt="Image 1"
                  />
                </Box>
                <Box sx={{ width: '48%', marginBottom: '1rem' }}>
                  <CardMedia
                    component="img"
                    image="https://storage.googleapis.com/app_darkendimg/assets/the_gap.jpg"
                    alt="Image 1"
                  />
                </Box>
                {/* Video */}
                <Box sx={{ width: '48%' }}>
                  <CardMedia
                    component="video"
                    controls
                  >
                    <source src="https://storage.googleapis.com/app_darkendimg/assets/434984382_7102179389891194_2078964461369976426_n.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </CardMedia>
                </Box>
                {/* Add more images and videos here */}
              </Box>
            </Grid>
            {/* Additional Content */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{
                backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/prize.webp')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: 400,
                borderRadius: 10,
                boxShadow: 0,
              }}>
                <CardContent>
                  <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary' }}>
                    Value Add
                  </Typography>
                  <Typography variant='body2' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary' }}>
                    Add value to your coffee
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </div>
  );
}

export default Value;
