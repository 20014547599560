import React, { useEffect, useRef, useState } from 'react';
import { Box, CardMedia } from '@mui/material';

const MediaItem = ({ item, title }) => {
  const isVideo = /\.(mp4|webm|mov)$/i.test(item);
  const videoRef = useRef(null); // Ref for the video element
 
  const [isPlaying, setIsPlaying] = useState(false);

  // Manage video play/pause behavior based on visibility
  useEffect(() => {
    let observer;
  
    const handleFullscreenChange = () => {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        videoRef.current?.pause(); // Ensure video is paused in fullscreen
      }
    };
  
    if (isVideo && videoRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (videoRef.current && !document.fullscreenElement) { // Avoid conflicts during fullscreen
            if (entry.isIntersecting) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
          }
        },
        { threshold: 0.5 }
      );
  
      observer.observe(videoRef.current);
  
      document.addEventListener('fullscreenchange', handleFullscreenChange);
      document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    }
  
    return () => {
      if (observer && videoRef.current) observer.unobserve(videoRef.current);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, [isVideo]);
  
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFullscreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      } else if (videoRef.current.webkitEnterFullscreen) {
        videoRef.current.webkitEnterFullscreen();
      }
    }
  };

  return (
    <Box sx={{ marginBottom: '10px', width: '100%' }}>
      
      {isVideo ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <video
          ref={videoRef}
          muted
          controls
          style={{ borderRadius: 10, width: '100%', padding: 0 }}
          playsInline // Prevents iOS from forcing fullscreen
        >
          <source src={item} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          </Box>
       </Box>
        
      ) : (
        <CardMedia
          component="img"
          image={item}
          alt={`${title}`}
          sx={{
            borderRadius: 2,
            padding: 0,
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
        />
      )}
      
    </Box>
  );
};

export default MediaItem;
