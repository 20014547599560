import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, CardContent, Typography, Tooltip, IconButton , Box} from '@mui/material';
import { UserContext } from '../../context/usercontext';
import { triggerMyCustomResolver } from './customresolver';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {gql, useMutation, useQuery} from '@apollo/client';

// query api_keys for the apiKey
const GET_API_KEY = gql`
   query GetApiKey($query: Api_keyQueryInput!) {
  api_key(query: $query) {
        userId
    apiKey{
    apiKey
   
    
    }
  }
}

`;

//mutation to delete api key
const DELETE_API_KEY = gql `
mutation DeleteApiKey($query: Api_keyQueryInput!) {
    deleteOneApi_key(query: $query) {
        apiKey{
            apiKey
        }
    }
    }
`;


const ApiKeyGenerator = () => {
  // Retrieve the user from context.
  const { user } = useContext(UserContext);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
    const [deleteApiKey] = useMutation(DELETE_API_KEY);
  
  const { loading: queryLoading, error: queryError, data } = useQuery(GET_API_KEY, {
    fetchPolicy: 'network-only', // Ensure fresh data is fetched
    variables: { query: { userId: user.id } }, // Make sure user.id is available
    skip: !user.id, // Skip if user ID is not available
    onCompleted: (data) => {
      if (data.api_key && data.api_key.length > 0) {
        setResult(data.api_key[0]); // Use the first document
      } else {
        setResult(null);
      }
    },
  });

 
 
  
  
  const mykey = result?.apiKey?.apiKey || null;
    console.log("API Key:", mykey);

    const handleDelete = async () => {
        try {
          await deleteApiKey({
            variables: { query: { userId: user.id } },
            update(cache, { data: { deleteOne_api_key } }) {
              cache.modify({
                fields: {
                  api_key(existingKeysRefs = [], { readField }) {
                    // If the cached value is not an array, return it as is.
                    if (!Array.isArray(existingKeysRefs)) {
                      return existingKeysRefs;
                    }
                    return existingKeysRefs.filter(
                      keyRef => readField('userId', keyRef) !== user.id
                    );
                  },
                },
              });
            },
          });
          setResult(null);
          alert("API key generated successfully.");
        } catch (error) {
          console.error("Error deleting API key:", error);
          alert("Failed to delete API key.");
        }
      };
      
      

  const handleCopy = async (key) => {
    try {
      await navigator.clipboard.writeText(key);
      alert("API key copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    setError(null);
    try {
      // Pass the input your resolver expects.
      const input = { userId: user.id };
      const res = await triggerMyCustomResolver(input);
      setResult(res);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

    if (loading || queryLoading) return <Typography>Loading...</Typography>;
    if (error || queryError) return <Typography color="error">Error: {error || queryError.message}</Typography>;
  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        textAlign: 'center', 
        marginTop: 4,
        maxHeight: '400px',      // Set a maximum height
        overflowY: 'auto'        // Enable vertical scrolling
      }}>
        <Card sx={{ maxWidth: 400, margin: 'auto', mt: 4, padding: 2 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Generate API Key
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 2, p: 2, gap: 2 }}>
  <Button
    variant="contained"
    onClick={handleDelete}
    sx={{
      backgroundColor: 'black',
      color: 'white',
      '&:hover': { backgroundColor: '#333' },
      flex: 1,
    }}
  >
    Generate
  </Button>
  <Button
    variant="contained"
    onClick={handleClick}
    disabled={loading}
    sx={{
      backgroundColor: 'black',
      color: 'white',
      '&:hover': { backgroundColor: '#333' },
      flex: 1,
    }}
  >
    {loading ? 'Processing...' : 'View Key'}
  </Button>
</Box>

            {result && result.apiKey && (
              <Typography variant="body1" color="primary" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ mr: 1, color: '#077336' }}>
                  {result.apiKey}
                </Typography>
                <Tooltip title="Copy API Key">
                  <IconButton onClick={() => handleCopy(result.apiKey)} size="small" sx={{ ml: 1 }}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}
          </CardContent>
          
          <Typography variant="body2" color="success" sx={{ mt: 2 }}>
            Generate a new API key to replace the existing one.
          </Typography>
        </Card>
        
      </Box>
      
  );
};

export default ApiKeyGenerator;
