import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Grid, LinearProgress, Button, Fab, Dialog, DialogActions, Chip, DialogTitle, DialogContent, CardContent, DialogContentText, Icon } from '@mui/material';
import { gql } from '@apollo/client';
import Heart from './displays/heart';

import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
// Register the moment adapter
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PriceTicket from '../finance/prods/pricefeed';
import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Flavloader from './flavloader';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import RevFeed from '../finance/prods/revolutionfeed';
import BeanCard from './beancard';
import StoreAdd from '../finance/adds/storeadd';
import StackableRoad from '../mods/displays/stackroad';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';
import GoalCard from '../finance/adds/goalcard';
import { color } from 'framer-motion';
import LikesTable from '../finance/beanwatch';
import BeanTicker from '../finance/beanwatchticker';
import { green } from '@mui/material/colors';


const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png';
const minted = 'https://storage.googleapis.com/app_darkendimg/assets/royal.png';
const coffeebeanies = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';

const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
    
  },
  progressContainer: {
    width: 300, 
    height: 30,
    
    borderRadius: 5,
  },
  progressFiller: {
    backgroundColor: 'lightgreen', 
  },
  chartContainer: {
     width: '100%',
  },
  chart: {
    height: 400,
  },
  progressText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  progressPercent: {
    fontWeight: 700,
    color: 'green',
  },
  progressLabel: {
    fontWeight: 400,
    color: 'black',
  },
  progressChip: {
    borderColor: 'green',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
  },
  progressChipLabel: {
    color: 'green',
  },
  progressChipIcon: {
    color: 'green',
  },
  progressChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  progressChipText: {
    fontWeight: 400,
    color: 'green',
  },
  progressChipValue: {
    fontWeight: 700,
    color: 'green',
  },
  progressChipIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
  },
  progressChipLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressChipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  needlelenth: {
    width: 400,
  },
}));




// Register the moment adapter

ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const GET_TOP_USERS = gql`
  query GetTopUsers {
    userprofs {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
    }
  }
`;

const BeanWatch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();
  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartReady, setChartReady] = useState(false); 
  const { data, loading, error } = useQuery(GET_TOP_USERS, {
    fetchPolicy: "network-only", // Ignores the cache and fetches directly from the network
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [openBoard, setOpenBoard] = useState(false);

  //Handle OPen Board
  const handleOpenBoard = () => {
    setOpenBoard(true);
  };

  const handleCloseBoard = () => {
    setOpenBoard(false);
  };

  
  const handlePresidentialButtonClick = (user) => {
    if (user._id !== _id) {
      setOpenDialog(true);
    } else {
      navigate('/us');
    }
  };
  
const handleCabinetButtonClick = (user) => {
    if (user._id !== _id) {
      setOpenDialog(true);
    } else {
      navigate('/us');
    }
  };
  const goToStore = () => {
    navigate('/store');
  };

  const handleBoardButtonClick = (user) => {
    if (user._id !== _id) {
      setOpenDialog1(true);
    } else {
      navigate('/us');
    }
  };
  
const open = () => {
  setOpenDialog1(true);
};
  
  const upgrade = () => {
    setOpenUpgrade(true);
  };

  useEffect(() => {
    // Assuming your data populates chartData somehow
    // When you receive your data and update chartData, also:
    setChartReady(true); 
  }, [data]); // Or another dependency depending on when you receive data 

      
  if (loading) return <Flavloader/>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  
  // Ensure data.userprof exists and is an array, then sort by beanCount and slice top 5
  const { userprofs: topUsers } = data;

  // Calculate total bean count across all usersUpgrade
  const totalBeanCount = topUsers.reduce((acc, user) => acc + user.beanCount, 0);


  // Sort users based on bean count (descending order)
  // Create a copy of topUsers arrayprogressPercent
const topUsersCopy = [...topUsers];

// Sort the copy based on bean count (descending order)
topUsersCopy.sort((a, b) => b.beanCount - a.beanCount);

// Take the top five users from the sorted copy
const topFiveUsers = topUsersCopy.slice(0, 100);
//display Top bean user
const isPresidential = topUsersCopy.slice(0, 1);
const isBoard = topUsersCopy.slice(1, 5);

//sort all users by bean count highest to lowest
const allsortedUsers = topUsersCopy.sort((a, b) => b.beanCount - a.beanCount);

const tokens = 91520000  ;
const progressPercent = (totalBeanCount / tokens) * 100; // Multiply by 100 to get percentage

const isSmartCitizen = beanCount?.$numberInt > 500;
const availabletokens = tokens - totalBeanCount;
const normalizedProgress = progressPercent * 100;
const cabinet = [
  { name: 'Secratary of State', role: 'state' , Link: '/',  },
  { name: 'Secratary of Defense', role: 'defense', Link: '/chat' ,  },
  { name: 'Secratary of Treasury',role: 'treasury', Link: '/us',  },
  { name: 'Secratary of Education',  role: 'education', Link: '/us',},
  { name: 'Secratary of Energy',  role: 'energy', Link: '/us', },
  { name: 'Secratary of Agriculture', role: 'agriculture', Link: '/us',  },
  { name: 'Secratary of Commerce',role: 'commerce', Link: '/us',  },
  { name: 'Secratary of Labor', role: 'labor',Link: '/us' ,},
  { name: 'Secratary of Health and Human Services',role: 'health',Link: '/us' ,},
  { name: 'Secratary of Housing and Urban Development',  role : 'urban', Link: '/us', },
  { name: 'Secratary of Transportation',role: 'transportation' ,  Link: '/us', },
  { name: 'Secratary of the Interior',role: 'interior', Link: '/us',  },
  { name: 'Secratary of Veterans Affairs', role: 'veterans', Link: '/us',  },
  { name: 'Secratary of Homeland Security,', role: 'homeland' , Link: '/us' ,},
];
 // Chart Data Preparation (assuming you want to visualize beanCounts of top users)
 const sortedUsers = topFiveUsers.sort((a, b) => a.beanCount - b.beanCount);
 const chartData = {
   datasets: [
     {
       
       data: sortedUsers.map((user) => ({
         x: user.Nickname, // Use nicknames on X-axis
         y: user.beanCount,
         img: user.imageurl,
         r: 20, // Adjust the base radius 
         progressPercent: (user.beanCount / tokens) * 100, 
       })),
     },
   ],
 };
<Typography variant="body1" color='text.secondary' sx={{ fontWeight: 600 }}>
{formatBeanCount(totalBeanCount)} In Circulation
</Typography>



const BarChartWithProgress = ({ chartData }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  // Prepare data: pass full data objects for each point.
  const data = {
    labels: chartData.datasets[0].data.map((dataPoint) => dataPoint.x),
    datasets: [
      {
        type: 'scatter',
        label: 'Top Users Bean Count',
        data: chartData.datasets[0].data, // full objects with x, y, img, etc.
        backgroundColor: '#32a852',
        // Your custom rendering logic if needed:
        render: (args) => {
          const { chart } = args;
          const { ctx, chartArea } = chart;
          const { data } = args.dataset;
          data.forEach((dataPoint, index) => {
            // Use the properties from the full dataPoint object
            const { x, y, progressPercent } = dataPoint;
            // Calculate coordinates (adjust as needed)
            const { left, right, top, bottom } = chartArea;
            const xCoord = left + (right - left) * (index + 0.5) / chart.data.labels.length;
            const yCoord = chart.scales.y.getPixelForValue(y);
            ctx.save();
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${x}: ${formatBeanCount(y)}`, xCoord, yCoord - 20);
            ctx.fillText(`${progressPercent.toFixed(2)}%`, xCoord, yCoord + 20);
            ctx.restore();
          });
        },
      },
    ],
  };

  // Add onClick to capture the clicked element.
  const options = {
    scales: {
      x: {
        type: 'category',
        ticks: {
          callback: (value) => value,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => formatBeanCount(value),
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        font: { weight: 'bold' },
        formatter: (value, context) => {
          const dataPoint = context.dataset.data[context.dataIndex];
          return `${dataPoint.x}: ${formatBeanCount(dataPoint.y)}`;
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 12,
        hitRadius: 15,
      },
    },
    onClick: (event, elements, chart) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedData = chart.data.datasets[0].data[index];
        setSelectedUser(clickedData);
      }
    },
  };

  return (
    <>
      <Scatter data={data} options={options} />
      {selectedUser && (
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            padding: 1,
            border: '1px solid #ddd',
            borderRadius: 2,
          }}
        >
          <img
            src={selectedUser.img}
            alt={selectedUser.x}
            style={{
              width: 50,
              height: 50,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <Typography variant="body1">
            {selectedUser.x}: {formatBeanCount(selectedUser.y)} beans
          </Typography>
        </Box>
      )}
    </>
  );
};

BarChartWithProgress.propTypes = {
  chartData: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
  }).isRequired,
};



  
  return (
    <>
   
 <BeanTicker />

        <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 2 }}>
  <Chip 
    label="My Bean Card" 
    onClick={() => handleOpenBoard()} 
    sx={{ backgroundColor: green[300], color: "#fff" }} 
  />
</Box>
<Dialog
      open={openBoard}
      onClose={() => setOpenBoard(false)}
      fullScreen={fullScreen}
      PaperProps={{
        sx: { borderRadius: fullScreen ? 0 : 2, m: fullScreen ? 0 : 2 },
      }}
    >
      <IconButton
        onClick={() => setOpenBoard(false)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'primary.main',
          backgroundColor: 'transparent',
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <DialogContent>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 3,
      textAlign: 'center',
    }}
  >
    <BeanCard />
    <Typography variant="body2" mt={2}>
      This is your Bean Card, your digital wallet to Koffieland. Use it to comment, like, share, add your products and a whole lot more.
    </Typography>
    <Typography variant="caption" color="textSecondary" mt={1}>
      DigiBeans are blockchain-verified, real digital assets that secure every transaction and interaction in our ecosystem.
    </Typography>
    <Button
      variant="contained"
      sx={{
        mt: 3,
        color: '#fff',
        background: '#077336',
        borderRadius: 5,
        px: 3,
        py: 1.5,
      }}
      onClick={goToStore}
    >
      Get Beans
    </Button>
  </Box>
</DialogContent>

    </Dialog>

<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0, borderRadius: 0, border: 'solid 0px #d9f0c2',  marginTop: 0 }}>
  <Dialog open={openUpgrade} onClose={() => setOpenUpgrade(false)}>
    <IconButton
      onClick={() => setOpenUpgrade(false)}
      sx={{
        marginLeft: 0,  // Ensures there's no additional space on the left
        color: 'primary.main',  // Optional: change the icon color if needed
        backgroundColor: 'transparent' , // Ensures the button background is transparent
        position: 'absolute', top: 8, right: 8, fontSize: 2, 
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogTitle variant='h5' sx={{fontWeight: 600}} >Upgrade to Smart Citizen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Become a lifetime Smart Citizen and enjoy all the benefits of being a citizen of KoffieLand with your first Purchase of 1lb, 4000 KoffieBeans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Chip label="Upgrade" onClick={() => navigate('/store')} sx={{ backgroundColor: '#32a852', color: '#fff' }} />
        </DialogActions>
      </Dialog>
  

 

  </Box>
 

  {chartReady &&  
        
        <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1, borderRadius: 4, boxShadow: 0 , mt: 2, }}
        > {/* Apply to the chart container */} 
          <BarChartWithProgress
         
          chartData={chartData} />
          <Typography variant="body2" color='text.secondary'sx={{ fontWeight: 300 }}>
        15 minute delay on data
        </Typography>
        </Box>
        
      
      } 

     
      
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1, borderRadius: 5, boxShadow: 0, marginTop: 0,
   
    }}>
       
      <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 600, marginBottom: '16px' }}>
        Big Bean Club
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {isPresidential.map((user, index) => (
          <div key={index} style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginRight: '16px' }}>
            <Avatar src={user.imageurl} alt={user.Nickname} sx={{ width: 100, height: 100, border: '4px solid', borderColor:'#000' }} />
            <Typography
  variant="caption"
  sx={{
    
  }}
>
  {user.Nickname}
  {user.beanCount > 300 && (
    <img src={kcoin} alt="Koffie Coin" style={{ width: 20, height: 20, marginLeft: 5 }} />
  )}
</Typography>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 400 }}>
              President of KoffieLand
            </Typography>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 800 }}>
            {formatBeanCount(user.beanCount)}
            </Typography>
            <Chip
             sx={{
              borderColor: '#32a852', // Green border color
              borderWidth: '1px', // Border width
              borderStyle: 'solid', // Border style
             
            
            }}
            label="Access" onClick={() => handlePresidentialButtonClick(user)} />
          </div>
          
        ))}
      </div>
      <Dialog  open={openDialog} onClose={() => setOpenDialog(false)}>
      <IconButton onClick={() => setOpenDialog(false)} sx={{ marginLeft: 0, color: 'primary.main', backgroundColor: 'transparent', position: 'absolute', top: 8, right: 8, fontSize: 2 }}>
      <CloseIcon />
    </IconButton>
        <DialogTitle variant='h6' fontWeight={800}>The Big Bean</DialogTitle>
        <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0,  }}>
          <img src='https://storage.googleapis.com/app_darkendimg/assets/royal.png' alt='bean' width={80} />
          </Box>
          <Typography variant='heading' fontWeight={600}>Top bean holder is the President.</Typography>
          <Typography variant="body1"> Access Top Secret information</Typography>
          <Typography variant="body1">Help Kraft the Koffieland Constitution</Typography>
        </DialogContent>
        <DialogActions>
        <Button sx={{color: '#fff', background: '#077336', borderRadius: 5}} onClick={goToStore}>Get Beans</Button>
        </DialogActions>
      </Dialog>
      <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 700, marginTop: '16px', marginBottom: '8px' }}>
        KoffieLand Board Members
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {isBoard.map((user, index) => (
          <div key={index} style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginRight: '16px' }}>
             <Link to={`/userprof/${user._id}`}>
             
            <Avatar label={user.beanCount} src={user.imageurl} alt={user.Nickname} sx={{ width: 60, height: 60, border: '4px solid', borderColor:'#32a852' }} />
            {user.beanCount > 300 && (
    <img src={kcoin} alt="Koffie Coin" style={{ width: 20, height: 20, position: 'relative', top: -10, right: -10 }} />
  )}
  
            </Link>
            <Typography
  variant="body2"
  sx={{
    
  }}
>
  {user.Nickname}
 
</Typography>
            <Typography variant="caption" color='text.secondary' sx={{ fontWeight: 800 }}>
            {formatBeanCount(user.beanCount)}
            </Typography>
            <Chip 
             label="Access" onClick={() => handleBoardButtonClick(user)} 
             sx={{
              borderColor: '#32a852', // Green border color
              borderWidth: '1px', // Border width
              borderStyle: 'solid', // Border style
              
            
            }}/>
          </div>
        ))}
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, borderRadius: 0, boxShadow: 0, overflow: 'auto',  }}>
  <Grid container spacing={2} justifyContent="center">
  {allsortedUsers.map((user, index) => (
     <Grid item xs={3} sm={4} md={3} key={user._id}>
     <Card sx={{ display: 'grow', padding: 1, alignItems: 'center' }}>
     <Link to={`/userprof/${user._id}`}>
     <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1, borderRadius: 5, boxShadow: 0, }}>
       <Avatar src={user.imageurl} alt={user.Nickname} sx={{ width: 40, height: 40 }} /> {user.beanCount > 300 && (
    <img src={kcoin} alt="Koffie Coin" style={{ width: 20, height: 20, marginLeft: 4, marginBottom: 20 }} />
  )}
  </Box>
       </Link>
       <Typography
  variant="caption"
  sx={{
    
  }}
>
  {user.Nickname}
 
</Typography>
       <Typography variant="body2" sx={{ fontWeight: 700 }}>{formatBeanCount(user.beanCount)} </Typography>
     </Card>
   </Grid>
    ))}
  </Grid>
</Box>
    </Box>

   <StoreAdd />
        

   
   {!isMobile && 
        <Box sx={{ position: 'fixed', bottom: '50px', left: 10, zIndex: 2000}}>
          <StackableRoad />
        </Box>
       }
   
   <Dialog open={openDialog1} onClose={() => setOpenDialog1(false)}>
    <IconButton onClick={() => setOpenDialog1(false)} sx={{ marginLeft: 0, color: 'primary.main', backgroundColor: 'transparent', position: 'absolute', top: 8, right: 8, fontSize: 2 }}>
      <CloseIcon />
    </IconButton>
        <DialogTitle variant='h6' fontWeight={800}>
          
          <Typography variant='h6' fontWeight={800}>Board Member</Typography>
          </DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0,  }}>
          <img src='https://storage.googleapis.com/app_darkendimg/assets/jetset.png' alt='bean' width={80} />
          </Box>
        <DialogContent>
          <Typography variant="heading" fontWeight={600}>Top 5 holders are the Cabinet members.</Typography>
          <Typography variant="body1"> Get Access to Koffiland Classified Content.</Typography>
          <Typography variant="body1"> Participate in Executive desicions</Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{color: '#fff', background: '#077336', borderRadius: 5}} onClick={goToStore}>Get Beans</Button>
        </DialogActions>
      </Dialog>
     
    
    </>
  );
};

export default BeanWatch;
