import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { Avatar, Box, Card, Chip ,Popover, Button, Menu} from '@mui/material';
import Display from '../profile/settings/displaymode';
import { useNavigate } from 'react-router-dom';
import { EmailOutlined, Home, LocalMallOutlined, NaturePeopleOutlined, PeopleOutlineOutlined,   } from '@mui/icons-material';
import LogoutButton from '../profile/logout';
import klogo from '../../images/kcoin.png';
import SideDrop from '../../pages/desk/accmenu';
import { useBag } from '../../context/BagContext';
//import  UserContext  from '../../context/usercontext';
import { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
//import BagIcon from '../finance/BagIcon';
import { UserContext } from '../../context/usercontext';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import beanbag from '../../images/beanbag.png';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useBeanBag } from '../../context/beanBagContext';
import bean from '../../images/brkoin.png';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useEffect, useRef  } from 'react';
import ToolTip from '@mui/material/Tooltip';
import BeanCount from '../mods/beancount';
import { useApolloClient } from '@apollo/client';
import { GET_MESSAGES, GET_SENT_MESSAGE, GET_REPLIES, GET_THREAD } from '../../pages/sendmessage';
import { useLazyQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: 5,
    boxShadow: 10,
    position: 'absolute',
    top: 0,
    
    
  },
  logo: {
    width: 50,
    borderRadius: 0,
  },
  ribbon: {
    position: 'absolute',
    bottom: 5, // Adjust the position of the ribbon vertically
    left: 50, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    
   
  },
  ribbonText: {
    color: 'black', // Adjust the text color of the ribbon
    fontWeight: 900, // Adjust the font weight of the ribbon text
    fontSize: 10, // Adjust the font size of the ribbon text
  },
  tooltipContent: {
    padding: theme.spacing(2),
  },
}));

const BagIcon = () => {
  return (
    
    <img src={beanbag} alt="koffie" width={20} />
  
  );
};
const KcoinIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="koffie" width={20} />
  );
};

const EthIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="koffie" width={20} />
  );
};

const MarketIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/marketgreen.png' alt="koffie" width={20} />
  );

};

const KoffiehandIcon = () => {
  return (
    <img src={bean} alt="koffie" width={20} />
  );
};

const KoffieIcon = () => {
  return (
    <img src={klogo} alt="koffie" width={20} />
  );
};

const path = [
  '/land',
  '/explore',
  '/chat',
  '/beanwatch',
  '/allkoffie',
  '/kcoin',
  '/middle',
  '/chron',
  '/shoptabs',
  '/reviews',
  '/store',
  '/post',
  '/privacy-policy',
  '/terms-of-service',
  "/web3market",
  "/messages",
  // Add more mappings as needed
];


export default function LeftBar() {
  const navigate = useNavigate();
  
  const [value, setValue] = React.useState(0); // Add this line to define the 'value' state
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl } = user.customData
  const classes = useStyles();
  const { itemCount } = useBag();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [postSubmitted, setPostSubmitted] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [step, setStep] = useState(0);
  const [fetchMessages, { loading: loadingMessages, data: dataMessages, error: errorMessages }] =
  useLazyQuery(GET_MESSAGES, {
    fetchPolicy: 'network-only', // Ensure fresh data
  });

const [fetchSentMessages, { loading: loadingSentMessages, data: dataSentMessages, error: errorSentMessages }] =
  useLazyQuery(GET_SENT_MESSAGE, {
    fetchPolicy: 'network-only', // Ensure fresh data
  });

const [fetchUnreadReplies, { loading: loadingReplies, data: dataUnreadReplies, error: errorUnreadReplies }] =
  useLazyQuery(GET_REPLIES, {
    fetchPolicy: 'network-only', // Ensure fresh data
  });
  const [getThread, { loading: loadingThread, data: dataThread, error: errorThread }] =
  useLazyQuery(GET_THREAD, {
    fetchPolicy: 'network-only', // Ensure fresh data
  });

  //get get_messages query and get_sent_message query and get_replies query

  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const steps = [
    {
      description: "Welcome to Bean Town! Click 'Next' to continue.",
      path: '/explore',
    },
    {
      description: "Explore the different states of Koffie in Koffie States.",
      path: '/allkoffie',
    },
    {
      description: "Discover various Koffie products in the Market Place.",
      path: '/user-market',
    },
    {
      description: "Read fascinating stories in the Koffie Chronicles.",
      path: '/chron',
    },
  ];

  const handleOpenModal = () => {
    setExpanded(true);
  };

  const handleCloseModal = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (postSubmitted) {
      // Close the modal after post is submitted
      setExpanded(false);
      
      setPostSubmitted(false); // Reset for next time
    }
  }, [postSubmitted]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleClose();
    }
  };

  const handleSkip = () => {
    handleClose();
    navigate(steps[step + 1].path);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setStep(0);
    setMenuPosition({
      top: event.currentTarget.offsetTop + event.currentTarget.offsetHeight,
      left: event.currentTarget.offsetLeft,
    });
  };

  useEffect(() => {
    // Trigger popover on page load
    const menuItem = document.getElementById(`menu-item-${step}`);
    if (menuItem) {
      handleMenuClick({ currentTarget: menuItem });
    }
  }, []); 

  const open = Boolean(anchorEl);
  const id = open ? 'menu-popover' : undefined;

  const { itemCount1 } = useBeanBag();

  const handleNavigate = (path) => {
    
    navigate(path);
  }

  const handleMessagesNavigate = async () => {
    try {
      // Run all queries in parallel
      await Promise.all([
        fetchMessages({ variables: { receiverId: user.id } }),
        fetchSentMessages({ variables: { senderId: user.id } }),
        fetchUnreadReplies({ variables: { receiverId: user.id } }),
        getThread({ variables: { userId: user.id } }),
      ]);
  
      // Navigate to messages after all queries resolve
      navigate('/messages');
    } catch (err) {
      console.error('Error fetching messages:', err);
      navigate('/messages'); // Navigate even if fetch fails
    }
  };
  

const handlelogoClick = () => {
  navigate('/bag');
}




const handleBeanClick = () => {
  navigate('/beanbag');
}




const content = () => {
  switch (null) {
    case 0:
      return <Typography>Step 1: Welcome to the guide! Click "Next" to continue.</Typography>;
    case 1:
      return <Typography>Step 2: Here's an overview of the menu.</Typography>;
    case 2:
      return <Typography>Step 3: Click on a menu item to learn more.</Typography>;
    default:
      return null;
  }
};


  return (
    <Box
  sx={{
    height: '100vh',
    overflowY: 'hidden', // Prevents scrollbars on parent
    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
  }}
>
  {user && (
    <Paper
    sx={{ 
      position: 'sticky', 
      top: 0, 
      height: '100%', 
      overflowY: 'auto', 
      p: 2, 
      scrollbarWidth: 'none', // Hides scrollbar in Firefox
      '&::-webkit-scrollbar': { display: 'none' } // Hides scrollbar in Chrome, Safari, etc.
    }}
    >
        <Box className={classes.boxContainer} sx={{ mb: 10}}>
        <img
    className={classes.logo}
    src={
      theme.palette.mode === 'dark'
        ? 'https://storage.googleapis.com/app_darkendimg/assets/klandwhite.png'
        : 'https://storage.googleapis.com/app_darkendimg/assets/kland.png'
    }
    alt="Koffie"
  />
              <Box className={classes.ribbon}>
                <div className={classes.ribbonText}>BETA</div>
              </Box>
              <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 700 }}>
                KOFFIELAND™
              </Typography>
            </Box>
      <MenuList
      sx={{mt: 10}}
      
      >

<MenuItem onClick={() => handleNavigate(path[11])} sx={{ py: 2 }}>
          <ListItemIcon>
            <LocalCafeOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Home
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 0 }}>
          <ListItemIcon>
           
          </ListItemIcon>
        </MenuItem>
        

        {/* Menu Items */}
        <MenuItem onClick={() => handleNavigate(path[10])} sx={{ py: 2 }}>
          <ListItemIcon>
            <EthIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Koffie Store
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleNavigate(path[14])} sx={{ py: 2 }}>
          <ListItemIcon>
            <HistoryEduOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Bean Heads
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleNavigate(path[1])} sx={{ py: 2 }}>
          <ListItemIcon>
            <PeopleOutlineOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Explore
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleNavigate(path[3])} sx={{ py: 2 }}>
          <ListItemIcon>
            <PeopleOutlineOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Bean Club
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => handleNavigate(path[4])} sx={{ py: 2 }}>
          <ListItemIcon>
            <NaturePeopleOutlined />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Koffie States
            </Typography>
          </ListItemText>
        </MenuItem>


        <MenuItem onClick={() => handleNavigate(path[7])} sx={{ py: 2 }}>
          <ListItemIcon>
            <HistoryEduOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Koffie History
            </Typography>
          </ListItemText>
        </MenuItem>


        <MenuItem onClick={() => handleNavigate(path[8])} sx={{ py: 2 }}>
          <ListItemIcon>
            <ShoppingBasketOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Market Place
            </Typography>
          </ListItemText>
        </MenuItem>

      
        <MenuItem onClick={() => handleNavigate(path[6])} sx={{ py: 2 }}>
          <ListItemIcon>
            <img src={beanbag} alt="koffie" width={25} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
              Bean Bag
            </Typography>
          </ListItemText>
          {itemCount1 > 0 ? (
            <Chip
              component={Link}
              to="/bean-bag"
              label={`${itemCount1} Beans Ahoy!`}
              onClick={handlelogoClick}
              icon={<KcoinIcon />}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 1,
                borderRadius: 5,
              }}
            />
          ) : (
            <BeanCount />
          )}
        </MenuItem>

        <MenuItem onClick={handleMessagesNavigate} sx={{ py: 2 }}>
  <ListItemIcon>
    <EmailOutlinedIcon />
  </ListItemIcon>
  <ListItemText>
    <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600 }}>
      Messages
    </Typography>
  </ListItemText>
</MenuItem>


        <MenuItem onClick={() => handleNavigate(path[5])} sx={{ py: 2 }}>
          
          <ListItemText>
            <LogoutButton />
          </ListItemText>
        </MenuItem>

        
        <Divider sx={{ my: 1 }} />
        <MenuItem sx={{ py: 2 }}>
          <ListItemIcon>
            <Display />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1 }}>
   <Typography variant="caption" sx={{ fontWeight: 700, textAlign: 'center' }}>
      © 2021 Koffie Inc. All rights reserved. Powered by Java.
    </Typography>
  </Box>
    </Paper>
  )}
 
</Box>

  );
}
