import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Button, Dialog, DialogContent, DialogActions, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function QRScannerDialog() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleScan = (result) => {
    console.log("Scanned Data:", result); // Debug: Log the scanned result
    if (result) {
      setData(result);
      if (result.startsWith("/claimbeans/")) {
        // Navigate to the route if it matches the expected format
        navigate(result);
      } else {
        alert("Invalid QR code URL");
      }
    }
  };

  return (
    <>
      {/* Button to trigger dialog */}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Scan QR Code
      </Button>

      {/* Dialog containing the scanner */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Scan QR Code
          </Typography>
          <BarcodeScannerComponent
            width={500}
            height={500}
            onUpdate={(err, result) => {
              if (result) {
                console.log("Scanner Output:", result.text); // Debug: Log raw scanner output
                handleScan(result.text); // Pass the scanned text to handler
              }
              if (err) {
                console.error("Scanner Error:", err); // Debug: Log any errors
              }
            }}
          />
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            {data || "Waiting for scan..."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QRScannerDialog;
