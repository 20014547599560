import React, { useState, useEffect,  useContext } from 'react';
import Plot from 'react-plotly.js';
import Typeit from '../mods/typeit';
//import CoffeePage from './des';
import { 
Card, Chip, List, Paper, Typography, Avatar, Button,
Box, Divider, CardContent,  Dialog, DialogContent, DialogContentText 
} from '@mui/material';
import { App } from 'realm-web';
import '../../App.css';
import { makeStyles } from '@mui/styles';
import '../../context/EarthMode.css';
import { Link } from 'react-router-dom';
import { ArrowBack, ListAlt } from '@mui/icons-material';
import {useQuery, gql, useMutation } from '@apollo/client';
//import Aifood from './aifood';
import Header from '../nav/mobilefarmer';
import { UserContext } from '../../context/usercontext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../constants/espresso.css';
import { useNavigate } from 'react-router-dom';
import Barista from '../tests/barista';
import Aifood from '../tests/aifood';
import OllieAI from './oliver';
import { AnimatePresence, motion } from 'framer-motion';
import { fontGrid } from '@mui/material/styles/cssUtils';
import { Close } from '@mui/icons-material';




//Mutate Koffie
const UPSERT_USERPROF = gql`
  mutation upsertOneUserprof($query: UserprofQueryInput, $data: UserprofInsertInput!) {
    upsertOneUserprof(query: $query, data: $data) {
      _id
      name
      description
      country   
      tasteProfile
      
    }
  }
`;

//this
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
      
    }
  }
`; 

const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
    author
    cherryCount
    Nickname
    Title
    _id
    Koffie_story
    imageurl
  }
}
`; 



const kupakoffie = () => {
    return {
      "name": "kupakoffie",
      "description": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the coffee"
          },
          "country": {
            "type": "string",
            "description": "Alternating between Ethiopia, Brazil, Jamaica, Costa Rica, Colombia, and Guatemala."
          },
          "description": {
            "type": "string",
            "description": "Short Whitty Description of the coffee"
          },
          "tasteProfile": {
            "type": "string",
            "description": "How i like my coffee"
          },
          "purchaseLink": {
            "type": "string",
            "description": "/koffie/country "
          },
          
        },
        "required": ["name", "country", "description", "tasteProfile",  "purchaseLink"]
      }
    };
  };


  const useStyles = makeStyles(({ palette }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 0,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 150,
      height: 150,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      color: 'inherit',
      marginBottom: '0.875em',
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      alignItems: 'center', // Centers the button vertically if needed
      width: '50%',
      height: '40px',
      borderRadius: 4,
      
      color: 'black',
      border: 'solid 1px #077336',  // Add a border around the button
      marginTop: 30,
      fontSize: 20,
      fontWeight: 700,
    },
  }));

 

const KoffieAI = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const { Nickname, imageurl } = user.customData;
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const styles = useStyles();
  const [name, setName] = useState("");
const [country, setCountry] = useState("");
const [flavorProfile, setFlavorProfile] = useState("");
const [description, setDescription] = useState("");
const [purchaseLink, setPurchaseLink] = useState("");
const [showCard, setShowCard] = useState(true);
const [showPlot, setShowPlot] = useState(true);
const navigate = useNavigate();



  const gotoProfile = () => {
    navigate('/main');
  };


  const { loading, error, data } = useQuery(GET_USER, {
    variables: { _id: user.id },
  });
  const [updateUserProf] = useMutation(UPDATE_USERPROF);
 
const userimage = data?.userprof?.imageurl;



  const [isButtonClicked, setButtonClicked] = useState(false);

  
  useEffect(() => {
    if (data?.userprof) {
      setName(data.userprof.name || "");
      setCountry(data.userprof.country || "");
      setFlavorProfile(data.userprof.flavorprofile || "");
      setDescription(data.userprof.description || "");
      setPurchaseLink(data.userprof.purchaseLink || "");
      
      // Hide the card and button when userprof data is available
      setShowCard(false);
    }
    // Revert to showing the card if data becomes null or undefined
    else {
      setShowCard(true);
    }
  }, [data]);

  
  
  
  
  const ChartData = [
    {
     
        maxdepth: 6,
        insidetextorientation: 'radial',
        textposition: 'inside',
        
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"  ], 
        marker: {line: {width: 2},
        colors: [
  // Base color for Coffee
  "#6F4E37", // Coffee Brown

  // Aroma category shades
  "#A0522D", // Sienna (Base for Aroma)
  
  // Subcategories for 'Aroma'
  "#A66E4A", // Floral Aroma
  "#AB8B67", // Fruity Aroma
  "#B1A884", // Sweet Aroma
  "#B6C6A1", // Nutty Aroma

  // Taste category shades
  "#8B4513", // Saddle Brown (Base for Taste)
  
  // Subcategories for 'Taste'
  "#9C5B22", // Sweetness
  "#AD7131", // Acidity
  "#BE8740", // Bitterness
  "#CF9D4F", // Saltiness

  // Dry Distillation category shades
  "#654321", // Dark Brown (Base for Dry Distillation)
  
  // Subcategories for 'Dry Distillation'
  "#745637", // Roasted
  "#83694D", // Smoky
  "#927C63", // Burnt
  "#A18F79", // Ashy

  // Fruity category shades
  "#FFA07A", // Light Salmon (Base for Fruity)
  
  // Subcategories for 'Fruity'
  "#FF8C69", // Berry Fruit
  "#FF7858", // Citrus Fruit
  "#FF6447", // Dried Fruit
  "#FF5036", // Other Fruits

  // Sweet category shades
  "#DAA520", // Goldenrod (Base for Sweet)
  
  // Subcategories for 'Sweet'
  "#E1AE37", // Brown Sugar
  "#E8B955", // Vanilla
  "#F0C373", // Honey
  "#F7CE91", // Caramel

  // Nutty/Cocoa category shades
  "#D2691E", // Chocolate (Base for Nutty/Cocoa)
  
  // Subcategories for 'Nutty/Cocoa'
  "#D97E35", // Nuts
  "#E0934C", // Dark Chocolate
  "#E8A963", // Milk Chocolate
  "#F0BE7A", // Cocoa

  // Spices category shades
  "#CD853F", // Peru (Base for Spices)
  
  // Subcategories for 'Spices'
  "#D69556", // Pungent
  "#DEA76D", // Pepper
  "#E7B984", // Brown Spice
  "#EFCA9B", // Anise

  // Floral category shades
  "#FFC0CB", // Pink (Base for Floral)
  
  // Subcategories for 'Floral'
  "#FFB6C1", // Jasmine
  "#FFA8B4", // Rose
  "#FF9AA7", // Hibiscus
  "#FF8C9A", // Lavender

  // Green/Vegetative category shades
  "#228B22", // Forest Green (Base for Vegetative)
  
  // Subcategories for 'Green/Vegetative'
  "#3A9B3A", // Fresh
  "#52AB52", // Hay-like
  "#6BBB6B", // Herbal
  "#83CB83", // Grassy

  // Other category shades
  "#808080", // Gray (Base for Other)
  
  // Subcategories for 'Other'
  "#8A8A8A", // Papery
  "#949494", // Musty
  "#9E9E9E", // Chemical
  "#A8A8A8", // Medicinal

  // Roasted category shades
  "#4B0082", // Indigo (Base for Roasted)
  
  // Subcategories for 'Roasted'
  "#5C1A8C", // Tobacco
  "#6D3397", // Burnt
  "#7E4DA1", // Smoky
  "#8F66AC", // Toasted

  // Earthy category shades
  "#556B2F", // Dark Olive Green (Base for Earthy)
  
  // Subcategories for 'Earthy'
  "#667A43", // Soil
  "#778958", // Moss
  "#88986C", // Damp
  "#99A780", // Woodsy
],

    }
    }
  ];

  
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  
  
  

  
  

  const handleClick = (data) => {
    if (data.points && data.points.length > 0) {
        const label = data.points[0].label;
        
        setClickedItems([...clickedItems, { label, }]);
        setButtonClicked(true);
    }
};
console.log(clickedItems);

const clearClickedItems = () => {
  setClickedItems([]);  // Reset clickedItems to an empty array
};

const handleUpdate = (outputData) => {
  updateUserProf({
    variables: {
      query: { _id: outputData._id },
      set: outputData.data
    }
  });
}

const layout = {
  width: !isMobile ? 600 : 400,
  margin: {l: 0, r: 0, b: 0, t: 0}, 
  showlegend: false,
  bordercolor: "#000",
  paper_bgcolor: theme.palette.background.default,
  fontSize: 12,
  
  
 
  
  height: 400,
  
 

};
const config = {
  displayModeBar: false,
  modeBarButtonsToRemove: ['sendDataToCloud'],  // removes the 'Edit in Chart Studio' button
  displaylogo: false,  // hides the Plotly logo
  responsive: true,  // makes the chart responsive to window size
  alignProperty: 'center',
  align: 'center',

};

const coffeeFunction = kupakoffie();  // Get the coffee function

  const handleSubmit = () => {
    setShowPlot(false); 
    setTimeout(() => {
      setIsLoading(false);  // Hide loading indicator
    }, 2000);  // Simula
    const profileData = clickedItems.map(item => item.label).join(', ');
    setIsLoading(true);  // Start loading before the fetch request

  
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
          {
              "role": "system", 
              "content": "You are the BEST Coffee salesman on the planet. Your goal is to sell as much koffie as you can. You are funny and witty. Recommend 2 coffees based on the user's taste preference passed below. Alternate the recommendations between Ethiopia, Brazil, Jamaica, Costa Rica, Colombia, Guatemala. Ensure variety in your suggestions."
          },
          {
              "role": "user", 
              "content": "This is my flavor profile: " + profileData + ". Based on this, recommend 2 coffees, alternating between Ethiopia, Brazil, Jamaica, Costa Rica, Colombia, and Guatemala."
          }
      ]
      ,
                  
                  "temperature": 0.9,
                  'model': 'gpt-4o',
                  "function_call": coffeeFunction,
                  'functions': [coffeeFunction],
                  
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          name: data.choices[0].message.content.name,
          country: data.choices[0].message.content.country,
          flavorprofile: data.choices[0].message.content.tasteProfile,
          description: data.choices[0].message.content.description,
          purchaseLink: data.choices[0].message.content.purchaseLink,
        };
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
      }
      
  
      setOutput(contentData); // Render the content on the frontend
      console.log('Success:', contentData);
      console.log('Total tokens used:', data.usage.total_tokens);
  
      // Construct the variables for the GraphQL mutation
      const output = {
        data: contentData,
        _id: user.id
      };
  
      // Perform the GraphQL mutation using the 'output' object
      handleUpdate(output);
     
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
      navigate('/main');
    });
      
  };
  
  const countryBeanMap = {
    Brazil: "https://storage.googleapis.com/app_darkendimg/assets/brazilbean-tran.webp",
    Jamaica: "https://storage.googleapis.com/app_darkendimg/assets/rastabean-tran.webp",
    Ethiopia: 'https://storage.googleapis.com/app_darkendimg/assets/addisbean-tran.webp',
   
    'Costa Rica': 'https://storage.googleapis.com/app_darkendimg/assets/costacoffee-tran.webp',
    Colombia: 'https://storage.googleapis.com/app_darkendimg/assets/bogotabean-tran.webp',
    Guatermala: 'https://storage.googleapis.com/app_darkendimg/assets/guatbean-tran.webp',
    // Add more countries and their respective image URLs as needed
  };

  return (

        <>
   
{isMobile && (
        
        <Box sx={{ flex: 'grow', padding: 0 ,mb: 10, zIndex: 1502, mt:1  }}>
       
  
       
        <Card
  sx={{
   
    boxShadow: 'none',
    padding: 2,
    display: 'flex',                // Add display flex
    flexDirection: 'column',        // Stack items vertically
    alignItems: 'center',           // Center horizontally
    justifyContent: 'center',       // Center vertically
  }}
>
<Box 
  sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', // Distribute items to the far left and right
    alignItems: 'center', 
    padding: 1 ,
    width: '100%', // Ensure the box takes full width
  }}
>
  {/* Arrow Icon on the Far Left */}
  <IconButton onClick={() => window.history.back()} sx={{ marginLeft: 0 }}>
    <ArrowBackIcon /> 
  </IconButton>

  {/* Text on the Far Right */}
  <Typography
    variant="h6"
    sx={{
      fontWeight: 500,
      color: 'text.secondary',
      textShadow: '1px 1px 1px #ebecf0',
      mb: 2,
      textAlign: 'right', // Align text to the right
    }}
  >
    Dial in your flavor profile
  </Typography>
</Box>

  <Plot
    data={ChartData}
    layout={layout}
    onHover={handleClick}
    showlegend={false}
    config={config}
    
  />
</Card>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1,  }}>
          {clearClickedItems && (
                <Chip label="Clear Selection" onClick={clearClickedItems}  sx={{color: '#077336', backgroundColor: '#fff', border: 'solid 1px #077336'}}/>
              )}
          </Box>
       {clickedItems.length > 0 && (
          <Card sx={{ boxShadow: 'none'}}>
            <CardContent>
              <Typography>
                Your selected flavor profile:
              </Typography>
              <List sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {clickedItems.map((item, index) => (
                  <Chip key={index} label={item.label} />
                ))}
              </List>
            
            </CardContent>
          </Card>
        )}
  
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
        <button onClick={handleSubmit} className={classes.buttonContainer}>
              Submit
            </button>
        </Box>
  
        {content && (
  <Dialog
    fullScreen
    open={true}
    onClose={() => setOutput('')}
    sx={{
      '& .MuiDialog-container': { // Ensure dialog container takes full height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiPaper-root': { // Remove extra padding from the dialog content
        margin: 0,
        padding: 0,
        height: '100vh',
        maxHeight: '100vh',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Ensure the box also takes full height
        width: '100%',
        overflow: 'auto', // Handle overflow content gracefully
        position: 'relative', // Required for positioning the close button
        padding: 0,
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 0, // Add spacing between elements
              textAlign: 'center',
              padding: 0,
            }}
          >
            
            <Card className={styles.card}>
              <Box sx={{ display: 'flex', position: 'relative', width: '100%', justifyContent: 'center' }}>
              <IconButton
              onClick={() => setOutput('')}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1000, // Ensure button is above other content
              }}
            >
              <Close />
            </IconButton>
            <Typography variant="h6" fontWeight={700} sx={{ mt:1 }}>
              {Nickname} Flavor Profile
            </Typography>
              </Box>
              <CardContent>
                <Avatar className={styles.avatar} src={imageurl} />
                <Typography className={styles.heading} align="center">
                  {content.name}
                </Typography>
                <Typography className={styles.subheader}>
                  {content.tasteProfile}
                </Typography>
                 {/* Country Image */}
            {content.country && (
              <img
                src={countryBeanMap[content.country] || "https://storage.googleapis.com/app_darkendimg/assets/defaultbean.webp"}
                alt={`${content.country} Bean`}
                style={{ width: 100, height: 100, objectFit: "contain", marginBottom: '1rem' }}
              />
            )}
                <Typography variant="h6">{content.country}</Typography>
                <Typography className={styles.statValue}>
                  {content.description}
                </Typography>
               

                <Link to="/main/">
                  <Button
                    sx={{
                      marginTop: 2,
                      width: '50%',
                      alignContent: 'center',
                      backgroundColor: '#077336',
                      color: '#fff',
                      borderRadius: 10,
                      border: 'none',
                      ':hover': {
                        backgroundColor: '#065528',
                      },
                    }}
                  >
                    Koffie Kit
                  </Button>
                </Link>
                
            
            
              </CardContent>
             
            </Card>
            
          </Box>
          
        </motion.div>
      </AnimatePresence>
    </Box>
  </Dialog>
)}

    {isLoading && (
          <Dialog open={isLoading}>
            <DialogContent>
              <DialogContentText>
                Brewing...
              </DialogContentText>
            </DialogContent>
            
          </Dialog>
        )}
      </Box>
      )}
    
    </>
  );
};

export default KoffieAI;
