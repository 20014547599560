import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import CommentIcon from '@mui/icons-material/Comment';
import StoreIcon from '@mui/icons-material/Store';
import { Link } from 'react-router-dom';
import Faq from '../../pages/faq';
import { Grid } from '@mui/material';
import LikesTable from './beanwatch';
import {CardContent} from '@mui/material';
import PostAdd from '@mui/icons-material/PostAdd';
import ThumbUp from '@mui/icons-material/ThumbUp';
import Comment from '@mui/icons-material/Comment';
import Store from '@mui/icons-material/Store';


const beanRewards = [
  { number: 1, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png', likeCount: 1 },
  { number: 2, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/hoodie_bean.png', likeCount: 5 },
  { number: 3, imgSrc: 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png', likeCount: 10 },
  { number: 4, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/purp.png', likeCount: 20 },
  { number: 5, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/tinbean.png', likeCount: 100 },
  { number: 6, imgSrc: 'https://storage.googleapis.com/app_darkendimg/youngbean.png', likeCount: 200 },
  { number: 7, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/trooper.png', likeCount: 300 },
  { number: 8, imgSrc: 'https://storage.googleapis.com/app_darkendimg/iceicebeaine.png', likeCount: 400 },
  { number: 9, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/punk_bean.png', likeCount: 500 },
  { number: 10, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png', likeCount: 1000 },
  { number: 11, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/jetset.png', likeCount: 2000 },
  { number: 12, imgSrc: 'https://storage.googleapis.com/app_darkendimg/newton.png', likeCount: 3000 },
  { number: 13, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/royal.png', likeCount: 4000 },
  { number: 14, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png', likeCount: 5000 },
  { number: 15, imgSrc: 'https://storage.googleapis.com/app_darkendimg/bennyborer.png', likeCount: -1 },


  // Add more as needed
];

const rewards = [
  { icon: <PostAdd />, text: "5 beans per post" },
  { icon: <ThumbUp />, text: "X Beans per like" },
  { icon: <Comment />, text: "3 beans per comment" },
  { icon: <Store />, text: "50 beans per product added to  AI store" },
  { icon: <Store />, text: "200 beans per item added to Smart Market" },
  { 
    icon: <Store />, 
    text: "Go to Rewards to see Drink redemption and terms", 
    link: "/reviews" 
  },
];

export default function BeanList() {
  return (

    
    <Box sx={{ width: '100%',  margin: '0 auto', mt: 1, padding: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: 700, textAlign: 'center', mb: 1 }}>
        Bean Head Rewards
      </Typography>
       <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 0, mb: 1 }}>
      {beanRewards.map((reward, index) => (
  <Grid 
    item 
    xs={2} // For very small screens, each icon takes 1/12th of the row (12 columns in total)
    sm={2} // For small screens, each icon takes 2/12th (6 columns per row)
    md={2} // For medium screens and above, each icon takes 2.4/12th (5 icons per row)
    key={index} 
    
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
  >
    <Avatar sx={{ width: 40, height: 40 }} src={reward.imgSrc} />
    <Typography variant="caption" sx={{ fontWeight: 700, textAlign: 'center' }}>
      {reward.likeCount}
    </Typography>
  </Grid>
))}
      </Box>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Box
      sx={{
        display: "flex",
        overflowX: "auto", // Horizontal scroll
        gap: 2, // Space between cards
        padding: 0,
        "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
      }}
    >
      {rewards.map((reward, index) => (
        <Card key={index} sx={{ minWidth: 200, maxWidth: 250, flexShrink: 0 , border: 'solid 1px #333' }}>
          <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
            {reward.icon}
            {reward.link ? (
              <Link to={reward.link} style={{ textDecoration: "none", color: "#077336", fontWeight: "bold" }}>
                <Typography variant="body1">{reward.text}</Typography>
              </Link>
            ) : (
              <Typography variant="body1">{reward.text}</Typography>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
     <LikesTable />
    </Box>
  );
}
