import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Button, Box, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors'; // Importing green color from MUI

const TokenUniversityDialog = ({ open, onClose, onDiscover }) => {
  return (
   
    <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    sx={{ 
      
     
      "& .MuiPaper-root": { // Targeting the inner Paper component
        borderRadius: "16px" ,// Adjust border-radius here
        backgroundColor: green [300], // Light grey background
        color: 'white', // White text color
      }
    }}
  >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Token University
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CardMedia
          component="img"
          height="300"
          image="https://storage.googleapis.com/app_darkendimg/assets/koffielandia.webp"  // Replace with your image path
          alt="Token University Banner"
          sx={{ borderRadius: 2 }}

        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="white">
            Where every cup of koffie fuels revolutionary ideas and redefines culture.
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button variant="contained"  onClick={onDiscover} sx={{ backgroundColor: '#fff', '&:hover': { backgroundColor: '#055826' }, color:"#077336" }}>
              Discover More
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TokenUniversityDialog;
