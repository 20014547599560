// userQueries.js
import { gql,  } from '@apollo/client';

export const GET_USER = gql`   
  query Userprof ($_id: ObjectId!)  {
    userprof (query: {_id: $_id}) {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
     
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
      profileBuilt
      redeemed
      waitlist
      cashapp
      cashappid
      zelle
      zelleid
      payout
    }
  }
`;


export const GET_REDEEMED_BY_SHOP = gql`
  query GetRedeemedByShop($shopId: ObjectId!) {
    redeem(query: {shop: $shopId}) {
      _id
      Title
      reward
      userID
      imageurl
      redeemedAt
      shop
    }
  }
`;

export const GET_REDEEMED_BY_USER = gql`
  query GetRedeemedByUser($userId: ObjectId!) {
    redeems(query: {userID: $userId}) {
      _id
      Title
      reward
      userID
      imageurl
      redeemedAt
      shop
    }
  }
`;



export const GET_KOFFY_DETAILS = gql`
  query GetKoffyDetails($countryName: String!) {
    koffy(query: {countryName: $countryName}) {
      countryName
      _id
      Pounds
    }
  }
`;

export const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      usertype
      beanCount
      imageurl
      profileBuilt
      isOnboard
      waitlist
      cashapp
      cashappid
      zelle
      zelleid
      payout
      
    }
  }
`;


export const INSERT_SHOP_REDEEM = gql`
mutation InsertShopRedeem($data: ShopredeemInsertInput!) {
  insertOneShopredeem(data: $data) {
    _id
    userId
    shopId
    beanCount
    timestamp
    name
    uimage
    payout

  }
}
`;



export const INSERT_USER_PROF = gql`
  mutation InsertUserprof($data: UserprofInsertInput!) {
    insertOneUserprof(data: $data) {
      _id
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      country
      usertype
      beanCount
      imageurl
      profileBuilt
      isOnboard
    }
  }
`;


 export const REDEEM_BEANS = gql`
 mutation insertOneRedeem($data: RedeemInsertInput!) {
   insertOneRedeem(data: $data) {
     _id
     Title
     reward
     userID
     imageurl
     redeemedAt
   }
 }
 `;

 export const GET_COFFEE_SHOP_BY_ID = gql`
 query getCoffeeShopById($_id: ObjectId!) {
   userprofs(query: {_id: $_id}) {
     _id
     Nickname  
     Title
     Koffie_story
     usertype
     imageurl
   }
 }
`;

export const GET_USERS_BY_TYPE = gql`
query GetUsersByType($userType: String!) {
  userprofs(query: { usertype: $userType }) {
    _id
    Nickname
    Title
    Koffie_story
    usertype
    imageurl
  }
}
`;

const GET_ALL_USERS = gql`
  query GetAllUsers {
    userprofs {
      _id
      Nickname
      Title
      beanCount
      Koffie_story
      usertype
      imageurl
    }
  }
`;

export const GET_ALL_POSTS = gql`
  query GetAllPosts {
    posts(sortBy: CREATEDAT_DESC) {
      _id
      content
      createdAt
      author
      imageurl
      userimage
      contentType
      userID
    }
  }
`;


// src/context/gqllogics.js
