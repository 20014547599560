import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { motion, AnimatePresence } from "framer-motion";

const GET_STORIES = gql`
  query {
    darkroads(limit: 100) {
      ImageUrl
      When
      How
      What
      Title
      _id
    }
  }
`;

const FactGen = () => {
  const [fact, setFact] = useState(null);
  const { loading, error, data } = useQuery(GET_STORIES);

  useEffect(() => {
    if (data && data.darkroads.length > 0) {
      generateFact();
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, [data]);

  const generateFact = () => {
    if (data && data.darkroads.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.darkroads.length);
      setFact(data.darkroads[randomIndex]);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Card sx={{ padding: "0rem", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", boxShadow: 0, borderRadius: 2 }}>
      <CardContent sx={{ padding: "0px" }}>
        <AnimatePresence mode="wait">
          {fact && (
            <motion.div
              key={fact._id}
              initial={{ opacity: 0, y: 20, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.95 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: 600, mb: "auto" }}
              >
                {fact.Title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: 0, fontWeight: 900, alignSelf: "flex-end" }}
              >
                {fact.When}
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 0, fontWeight: 400, alignSelf: "flex-end" }}
              >
                {fact.What}
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
};

export default FactGen;
