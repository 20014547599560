import React from 'react';
import Plot from 'react-plotly.js';
import data from './wheel.json';
import { Box } from '@mui/material';
import { alignProperty } from '@mui/material/styles/cssUtils';

function Kwheel() {
    const trace = {
        type: "sunburst",
        labels: data.labels,
        parents: data.parents,
        values:  data.values,
        outsidetextfont: {size: 20, color: "#377eb8"},
        leaf: {opacity: 0.4},
        marker: {line: {width: 1}},  // change the width here
    };

    const layout = {
        margin: {l: 0, r: 0, b: 0, t: 0},
        width: 360,
        height: 350,
        backgroundColor: '#ddd',
    };

    const config = {
        displayModeBar: true,
        modeBarButtonsToRemove: ['sendDataToCloud'],  // removes the 'Edit in Chart Studio' button
        displaylogo: false,  // hides the Plotly logo
        responsive: true,  // makes the chart responsive to window size
        alignProperty: 'center',
        

    };

    return (
        <Box maxWidth="sm" sx={{flexGrow: '1'}}>
        <Plot
            data={[trace]}
            layout={layout}
            config={config}
        />
        </Box>
    );
}

export default Kwheel;
