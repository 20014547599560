import React from 'react';
import { Container, Typography, Divider, Box } from '@mui/material';

const ConceptDetailPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Koffieland Concept
      </Typography>
      <Divider sx={{ my: 4 }} />

      {/* 1. Concept Overview */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          1. Concept Overview
        </Typography>
        <Typography variant="h6" gutterBottom>
          Digital-Physical Integration:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Introduce koffieland as both a digital platform powered by blockchain coffee tokens (4,000 beans per lb of koffie) and a forthcoming physical venue where the community can celebrate coffee culture.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Token Ecosystem & Community:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Explain the unique role of coffee tokens as both a digital currency and a redeemable asset, driving engagement in the app (e.g., posting, liking, sharing, and purchasing) and fostering a vibrant Bean Club ranking system.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Historical & Cultural Inspiration:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Emphasize how the venue is a celebration of koffie’s transformative impact on society—drawing inspiration from historical figures (like Newton and Beethoven) and revolutionary movements sparked by the simple act of sharing coffee.
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* 2. The Digital Backbone */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          2. The Digital Backbone
        </Typography>
        <Typography variant="h6" gutterBottom>
          Blockchain-Driven Authenticity:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Highlight the innovative NFT contract that represents 23K lbs of koffie—about 90 million beans—and its function in creating a secure, transparent ecosystem where every bean has value.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Bean Heads NFT Characters:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Introduce the Bean Heads as the digital ambassadors of the koffieland ecosystem, symbolizing the playful yet innovative spirit of the platform.
        </Typography>
        <Typography variant="h6" gutterBottom>
          User Engagement & Rewards:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Detail how users earn tokens with every koffie purchase, enhancing the social currency and providing tangible rewards, from AI tools to physical coffee redemptions in participating shops.
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* 3. The Physical Venue Concept */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          3. The Physical Venue Concept
        </Typography>
        <Typography variant="h6" gutterBottom>
          Coffee Wonderland Experience:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Imagine a Willy Wonka–style koffie factory where art, history, and technology merge. This venue would feature immersive installations, interactive displays, and themed rooms celebrating coffee’s role in societal transformation.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Historical Journey & Installations:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Innovation Hubs:</strong> Spaces dedicated to historical figures and their contributions—think interactive displays where visitors can learn how coffee fueled breakthroughs in science, music, and culture (e.g., Newton’s inspirations or Beethoven’s 60 beans per cup mythos).<br />
          <strong>Cultural Corridors:</strong> Walk-through exhibits tracing the global journey of koffie—from its origins to its role in sparking revolutions and creative movements.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Creative & Social Zones:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Penny University Revived:</strong> Areas for collaborative creativity, networking, and workshops, echoing the traditional “Penny Universities” where coffee replaced alcohol as the medium for intellectual exchange.<br />
          <strong>Tech Meets Tradition:</strong> Zones where users can engage with both digital interfaces (for example, linking their bean tokens to live displays) and traditional coffee experiences.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Operational Hours & Atmosphere:
        </Typography>
        <Typography variant="body1" gutterBottom>
          A schedule that balances daytime community gatherings with a creative after-hours vibe. While a standard closing time (e.g., 8 pm) may work, consider periodic extended hours for special creative events or late-night social sessions that cater to artistic minds.
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* 4. Bridging Digital and Physical Worlds */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          4. Bridging Digital and Physical Worlds
        </Typography>
        <Typography variant="h6" gutterBottom>
          In-App to Venue Transition:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Articulate the campaign strategy where proceeds from the digital token sales fund the creation of the physical venue, making users part of the journey from online engagement to real-world impact.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Real-World Redemption:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Outline how bean tokens can be redeemed for beverages and experiences at the venue, ensuring that digital achievements translate into tangible benefits.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Community-Building Initiatives:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Encourage participation by integrating events, live creative sessions, and historical talks that bridge the gap between digital interactions and in-person experiences.
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* 5. Marketing & Campaign Strategy */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          5. Marketing & Campaign Strategy
        </Typography>
        <Typography variant="h6" gutterBottom>
          Storytelling & Narrative:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Develop a compelling narrative that frames koffieland as a transformative movement—a fusion of technology, history, and the shared passion for koffie.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Cross-Platform Engagement:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Leverage social media, in-app promotions, and community events to drive token sales and build anticipation for the physical venue opening.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Partnerships:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Consider collaborations with coffee shops, historical societies, art collectives, and tech innovators to enhance credibility and broaden the audience.
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* 6. Closing Vision */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          6. Closing Vision
        </Typography>
        <Typography variant="h6" gutterBottom>
          A New Era of Coffee Culture:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Conclude with a vision of koffieland as the nexus where modern blockchain innovation meets the rich tapestry of coffee history—a space that redefines how we experience, share, and innovate over koffie.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Invitation to Join the Journey:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Invite investors, partners, and the community to participate in this transformative project, making every cup of koffie a catalyst for creativity and change.
        </Typography>
      </Box>
    </Container>
  );
};

export default ConceptDetailPage;
