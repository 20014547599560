import React from 'react';
import { useBag } from '../../context/BagContext';
//import { product } from './Product';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Check, Close } from '@mui/icons-material';
import CheckoutForm from './CheckoutForm';
import AddressForm from './AddressForm';
import { Box, Stack, Card, Typography, Button, CardActionArea, Chip, IconButton, CardMedia,
ListItem, ListItemText, List, ListItemAvatar, Avatar,  
Divider

} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import  apiUrl  from './apiConfig';
import { useNavigate } from 'react-router-dom';
//import Koffieman from './delivery';
import Koffieticket from './prods/coffee';
import Progress from '../mods/progress';
import { makeStyles } from '@mui/styles';
import StoreEquip from './adds/k-equip';
import AiGen2 from './adds/aifeed2';
import { useQuery, gql , useMutation } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';


//Bean Icon
const BeanIcon = () => (
  <img src="https://storage.googleapis.com/app_darkendimg/lilbeanie.png" alt="Bean" style={{ width: 20, height: 20 }} />

);


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: 10,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
   
    
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: 10,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 0,
    fontSize: 16,
    fontWeight: 400,
  },
  logo: {
    width: 40,
    borderRadius: 0,
   
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 20, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    width: 100, // Adjust the width of the ribbon
    
   
  },
  ribbonText: {
    color: 'green', // Adjust the text color of the ribbon
    fontWeight: 300, // Adjust the font weight of the ribbon text
    fontSize: 12, // Adjust the font size of the ribbon text
  },
  PoweredByStripe: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    '& span': {
      fontSize: 12,
      marginRight: 5,
    },
  },

}));

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: false }) {
      userID
      beanies
      isPaid
      productId
      category
      beanies
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;



export const DELETE_ONE_ITEM = gql`
mutation DeleteOneItem($_id: ObjectId!) {
  deleteOneCart(query: {_id: $_id}) {
    _id
  }
}
`;  

// Powered by Stripe logo
const PoweredByStripe = () => (
  <div className="powered-by-stripe">
    <span>Powered by</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="25" viewBox="0 0 60 25">

    </svg>
  </div>
);

const Bag = () => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { itemsInBag , setItemsInBag, } = useBag();
  const [clientSecret, setClientSecret] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [error, setError] = useState(null);
  const options = { clientSecret };
  const navigate = useNavigate();
  
  


const handleUncollaborate = collaboratorId => {
    // ... logic to uncollaborate ...
    console.log(collaboratorId);
};

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);



  const goToStore = () => {
    navigate('/store');
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id , },
    skip: !user.id,
  });
  console.log("cartData:", cartData);

  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          category: item.category,
          beanies: item.beanies,
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData, ]);

  const rewards = cartData?.carts.map((item) => item.beanies);
  const totalRewards = rewards && rewards.length > 0
? rewards.reduce((acc, item) => acc + item, 0)
: 0;



  
  const [deleteOneItem] = useMutation(DELETE_ONE_ITEM);


  

  
  const stripePromise = loadStripe('pk_test_51NZcvKEc7qSvD2ZQp8gLEkwfx1xajLS5BoXXEPbdKX85XvCwGkaV74CIokFwlhFYcqtwRZNiKUYewlFfYXNcIvs400KuyHL2Dz'); // Replace with your publishable key

  console.log('Items in bag:', itemsInBag);
  useEffect(() => {
    fetch(`${apiUrl}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: itemsInBag }),
     
      
     
    })
    
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError('Bag is Empty. Continue shopping.');
          console.log('Items in bag:', itemsInBag);

        } else {
          setClientSecret(data.clientSecret);
        }
      })
      .catch((error) => {
        setError( <Koffieticket/>);
      });
  }, [itemsInBag]);
  
  const handleDeleteItem = (index) => {
    deleteOneItem({ variables: { _id: itemsInBag[index]._id } });
    const newItemsInBag = itemsInBag.filter((item, i) => i !== index);
    setItemsInBag(newItemsInBag);
  };
 
  
  
  

  const handleProceedToCheckout = () => {
    setShowCheckout(true);
  };

//Clear Items in Bag



  // Filter products based on if isPaid is false
  const itemsInBagFiltered = itemsInBag.filter(item => !item.isPaid === true);
  console.log('Items in bag filtered:', itemsInBagFiltered);

  

  // Calculate the total in cents:
const total = itemsInBagFiltered.reduce((sum, item) => {
  return sum + (item?.price || 0); // each item.price is already in cents
}, 0);

// 1. Define your threshold and shipping fee in cents
const FREE_SHIPPING_THRESHOLD = 4900; // $49 in cents
const SHIPPING_FEE_CENTS = 600;       // $6 in cents

// 2. Check if cart has a 'Payout' item
//    (Assuming itemsInBagFiltered is your array of cart items)
const hasPayoutItem = itemsInBagFiltered.some(
  (item) => item.category === 'Payout'
);

// 3. Decide shipping cost in cents
//    - If cart has a 'Payout' item, set shipping to 0
//    - Else, if total > 4900 cents ($49), shipping = 0
//    - Otherwise, shipping = $6 (600 cents)
let shippingCostCents = 0;
if (!hasPayoutItem) {
  shippingCostCents = total > FREE_SHIPPING_THRESHOLD ? 0 : SHIPPING_FEE_CENTS;
}

// 4. Convert to dollars for display
//    total is already the sum of item prices in cents
const displaySubtotal = (total / 100).toFixed(2);
const displayShipping = (shippingCostCents / 100).toFixed(2);
const displayFinalTotal = ((total + shippingCostCents) / 100).toFixed(2);

// 5. If you have an "amountToFreeShippingInCents" variable
//    It's only relevant if !hasPayoutItem, because Payout items bypass shipping anyway.
//    But if you still want to display, do:
const amountToFreeShippingInCents = FREE_SHIPPING_THRESHOLD - total;
const displayAmountToFreeShip = (amountToFreeShippingInCents / 100).toFixed(2);





  const progress = (total / 44) * 100;
  if (cartLoading) return <div>Loading...</div>;
  if (cartError) return <div>Error: {cartError.message}</div>;
  
  return (
    <div style={{ padding: 8, marginBottom: 50}}>
    {error ? (
      <div>
        <p>{error}</p>
        <button className={classes.buttonContainer} onClick={goToStore}>Continue Shopping</button>
      </div>
    ) : (
      <div>
       <Card sx={{ borderRadius: 0, mt: 2 , boxShadow: 0}}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
    <Typography variant="body1" gutterBottom>
      Shopping Bag
    </Typography>
    <Chip onClick={goToStore} sx={{ backgroundColor: 'transparent', border: ' solid 1px #077336' }} label="Continue Shopping" />
  </Box>
</Card>

      

        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            {!showCheckout ? (
             <>
            {amountToFreeShippingInCents > 0 && !hasPayoutItem ? (
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        ${displayAmountToFreeShip} to free shipping
      </Typography>
    ) : (
      <Typography variant="body1" sx={{ color: '#077336', fontWeight: 700 }}>
        You have free shipping!
      </Typography>
    )}
         
             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Typography variant="h6">Subtotal ${displaySubtotal}</Typography>
               <Typography variant="h6">Shipping ${displayShipping}</Typography>
               <Typography variant="h6">Total ${displayFinalTotal}</Typography>
             </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', mt:1 }}>
                  <button className={classes.buttonContainer} onClick={handleProceedToCheckout}>Checkout</button>
                  </Box>
<List>
  {itemsInBagFiltered && itemsInBagFiltered.length > 0 ? (
    itemsInBagFiltered.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            padding: 2, 
            borderBottom: '1px solid #f0f0f0' // Subtle divider 
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt={item.name} 
              src={item.imageurl} 
              sx={{ width: 60, height: 60, mr: 2 }} 
            />
          </ListItemAvatar>
          <ListItemText
            primary={item.name}
            secondary={
              <Typography variant="body2" color="textSecondary">
                ${(item.price / 100).toFixed(2)}
              </Typography>
            }
            sx={{ flexGrow: 1 }} // Allow the text to take up available space
          />
           <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {item.category === 'Koffie' && item.beanies && ( // Check if item.beanies exists
              <Chip 
              avatar={<Avatar><BeanIcon /></Avatar>} 
              label={`${item.beanies} Tokens`} 
            />
            )}
          </Box>
          <IconButton 
            edge="end" 
            aria-label="delete" 
            onClick={() => handleDeleteItem(index)}
          >
            <Close />
          </IconButton>
        </ListItem>
      </React.Fragment>
    ))
  ) : (
    <Typography variant="body1" align="center" sx={{ py: 3 }}>
      Your bag is empty.
    </Typography>
  )}
</List>
<Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mb: 4, // Margin at the top to create space from the checkout content
    }}
  >
    <img 
      src="https://storage.googleapis.com/app_darkendimg/assets/Powered%20by%20Stripe%20-%20blurple-300x68-b3bf095.png" // Path to your Stripe logo
      alt="Powered by Stripe" 
      style={{ width: '100px', height: 'auto' }} // Adjust the size as needed
    />
  </Box>

                  <Box sx={{}}>
                    <AiGen2/>
               <StoreEquip/>
               </Box>   
              </>
            ) : (
              <Stack spacing={2}>
                <Stack sx={{padding: '30px'}}> <AddressForm /> </Stack>
              <Stack sx={{padding: '30px'}}> <CheckoutForm items={itemsInBag} /></Stack>
             
          </Stack>
              
            )}
            
          </Elements>
        ) : (
          <p><Progress/></p> // Display loading message while fetching client secret
        )}
      </div>
    )}
 
  </div>
  );
};

export default Bag;

