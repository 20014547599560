import { useState } from 'react';
import axios from 'axios';

const useFileUpload3 = () => {
  const [files, setFiles] = useState([]);
  const [previewURLs, setPreviewURLs] = useState(Array(5).fill(null));
  const [uploadURLs, setUploadURLs] = useState(Array(5).fill(null)); // Store the actual upload URLs
  const [errors, setErrors] = useState(Array(5).fill(''));

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp'];
      const validVideoTypes = ['video/mp4', 'video/quicktime'];
      
      // Check if file type is valid
      if (validImageTypes.includes(file.type) || validVideoTypes.includes(file.type)) {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        // Preview URL (optional: keep it for user preview)
        const newPreviewURLs = [...previewURLs];
        newPreviewURLs[index] = URL.createObjectURL(file);
        setPreviewURLs(newPreviewURLs);

        setErrors(prevErrors => {
          const newErrors = [...prevErrors];
          newErrors[index] = '';
          return newErrors;
        });
      } else {
        setErrors(prevErrors => {
          const newErrors = [...prevErrors];
          newErrors[index] = 'Only images and videos (MP4, MOV) are allowed.';
          return newErrors;
        });
      }
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);

    const newPreviewURLs = [...previewURLs];
    newPreviewURLs[index] = null;
    setPreviewURLs(newPreviewURLs);

    const newUploadURLs = [...uploadURLs];
    newUploadURLs[index] = null;
    setUploadURLs(newUploadURLs);

    const newErrors = [...errors];
    newErrors[index] = '';
    setErrors(newErrors);
  };

  /**
   * Uploads all *non-null* files (1 to 5).
   * Returns an array of uploaded URLs (same order as files).
   * If no files or upload error, returns null.
   */
  const uploadFiles = async (userId) => {
    // Filter out any null entries
    const validFiles = files.filter(file => file !== null);

    // Require at least 1 file
    if (validFiles.length < 1) {
      // At least 1 image required
      setErrors(prevErrors => {
        const newErrors = [...prevErrors];
        // Optionally set a specific message somewhere, e.g. the first slot
        newErrors[0] = 'Please upload at least 1 file.';
        return newErrors;
      });
      return null;
    }

    // Clear existing errors
    setErrors(Array(5).fill(''));

    const newUploadURLs = Array(5).fill(null);

    try {
      // Loop through valid files and upload them
      for (let i = 0; i < validFiles.length; i++) {
        const formData = new FormData();
        formData.append('file', validFiles[i]);
        formData.append('userId', userId);

        // Upload each file to your endpoint
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.url) {
          // Store the URL in the corresponding slot
          // We use the index in validFiles, but you can adapt logic if needed
          const originalIndex = files.indexOf(validFiles[i]);
          newUploadURLs[originalIndex] = response.data.url;
        } else {
          // If server didn't return a URL
          setErrors(prevErrors => {
            const newErrors = [...prevErrors];
            newErrors[i] = 'Failed to get URL from server';
            return newErrors;
          });
        }
      }

      setUploadURLs(newUploadURLs);
      return newUploadURLs;
    } catch (error) {
      console.error('File upload failed:', error);
      setErrors(['Error uploading files. Please try again.']);
      return null;
    }
  };

  return {
    files,
    previewURLs,
    uploadURLs, // Return the uploaded URLs
    errors,
    handleFileChange,
    handleRemoveFile,
    uploadFiles,
  };
};

export default useFileUpload3;
