// myCustomResolverCaller.js
import * as Realm from "realm-web";

// Initialize the Realm App. Replace with your actual App ID.
const app = new Realm.App({ id: "application-0-jyiid" });

/**
 * Triggers the custom Realm function "myCustomResolver" with the provided input.
 * 
 * @param {Object} input - The input to pass to your custom resolver.
 * @returns {Promise<Object>} - The result returned by the custom resolver.
 */
export async function triggerMyCustomResolver(input) {
  // Ensure the user is authenticated.
  if (!app.currentUser) {
    try {
      // For example, use anonymous credentials (or use email/password as needed).
      await app.logIn(Realm.Credentials.anonymous());
    } catch (err) {
      console.error("Failed to log in:", err);
      throw err;
    }
  }

  try {
    // Call the custom Realm function "myCustomResolver" with the input as an argument.
    const result = await app.currentUser.callFunction("myCustomResolver", [input]);
    return result;
  } catch (error) {
    console.error("Error calling myCustomResolver:", error);
    throw error;
  }
}
