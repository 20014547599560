import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { UserContext } from '../../../context/usercontext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
//import lovekoffie from '../../../images/lovekoffie.webp';


const lovekoffie = 'https://storage.googleapis.com/app_darkendimg/newton.png';

function HealthPop() {
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Check if the "showedSnackbar" key exists in sessionStorage
    if (!sessionStorage.getItem("showedSnackbarKoffieland")) {
      setOpen(true);
      sessionStorage.setItem("showedSnackbarKoffieland", "true"); // Set the key after showing the Snackbar
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    ContentProps={{
      sx: {
        backgroundColor: '#ffffff', // Custom background color
        color: '#000000', // Custom text color
      },
    }}
    message={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Card
          sx={{
            maxWidth: 80,
            minWidth: 80,
            minHeight: 80,
            maxHeight: 80,
            borderRadius: 5,
            boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
            backgroundImage: `url(${lovekoffie})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginRight: 2, // Adds spacing between the image and text
            backgroundColor: 'transparent',
          }}
        />
        <div>
          <Typography variant="h6" sx={{ fontWeight: 400 }} gutterBottom>
            Welcome to Koffieland!
          </Typography>
          <Typography variant="body2">
            Explore the world of Koffieland and discover the best coffee beans from around the world.
          </Typography>
        </div>
      </div>
    }
  />
  
  );
}

export default HealthPop;
