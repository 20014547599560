// BaristaAd.js
import { Button, Card, Typography, Box, CardContent, Paper, CardMedia, Avatar, Chip, Grid, CardActionArea, CardActions } from '@mui/material';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// Get products as usertype barista
const GET_BARISTA_PRODUCTS = gql`
  query GetBaristaProducts {
  products(query: { usertype: "barista" }) {
    _id
    name
    price
    usertype
    imageurl
    ownername
    ownerimage
    description1
  }
}
`;

const BaristaAd = () => {
  const { loading, error, data } = useQuery(GET_BARISTA_PRODUCTS);
  const navigate = useNavigate();

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading products.</Typography>;

  return (
    <Box sx={{ backgroundColor: '#ebecf0', padding: 2, borderRadius: 0 }}>
      <Card
        sx={{
          padding: '20px',
          textAlign: 'center',
          borderRadius: 4,
          
          backgroundImage: `url("https://storage.googleapis.com/app_darkendimg/baristababe.webp")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        <Typography variant='h3' sx={{ fontWeight: 700, color: '#fff', paddingTop: 5, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
        Baristas Closet
      </Typography>
      <Typography variant='h4' sx={{ color: '#fff', fontWeight: 500 , textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}> 
        They're Brewing More Than Coffee
      </Typography>
      <Typography variant='subtitle1' sx={{ color: '#fff', fontWeight: 300, paddingTop: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
        Koffieland supports baristas by empowering them to sell new & used items.
      </Typography>
      <Button
        variant="contained"
        sx={{
          color: '#fff',
          fontWeight: 600,
          backgroundColor: '#261c03',
          marginTop: 4,
          borderRadius: 5,
          width: '80%',
          
        }}
      >
        Shop & Support Now
      </Button>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Typography variant="h2" sx={{ color: '#fff', opacity: 0.8 }}>
          ↓
        </Typography>
      </Box>
      </Card>
     
      {/* Scrollable product map below the card */}
      
      <Grid container justifyContent="center" sx={{ padding: 0, mb: 0 }}>
  <Box
    sx={{
      display: 'flex',
      gap: 2, // Adds spacing between cards
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      py: 1,
      '&::-webkit-scrollbar': { display: 'none' }, // hides scrollbar
    }}
  >
    {data.products.map((product) => (
      <Grid item xs={6} sm={4} md={6} key={product._id}>
        <Card
          sx={{
            borderRadius: 3,
           
            transition: 'transform 0.3s ease',
            '&:hover': { transform: 'scale(1.05)' },
            position: 'relative',
          }}
        >
          <CardActionArea onClick={() => navigate(`/product/${product._id}`)}>
            {/* Product Image */}
            <CardMedia
              component="img"
              height="140"
              image={product.imageurl}
              alt={product.name}
              sx={{ objectFit: 'cover', borderRadius: '12px 12px 0 0' }}
            />

            {/* Overlay Text */}
            

            {/* Product Content */}
            <CardContent>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {product.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limit to 2 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product.description1}
                  </Typography>
              <Typography variant="h6" color="text.secondary" fontWeight="600">
                ${(product.price / 100).toFixed(2)}
              </Typography>
            </CardContent>

            {/* Card Footer */}
            <CardActions sx={{ justifyContent: 'space-between', p: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Avatar src={product.ownerimage} alt={product.ownername} sx={{ width: 24, height: 24 , mr: 1}} />
                <Typography variant="caption" color="text.secondary">
                  {product.ownername}
                </Typography>
              </Box>

              <Chip label="Details" sx={{ backgroundColor: '#077336', color: '#fff' }} />
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Box>
</Grid>

    </Box>
  );
};

export default BaristaAd;